import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import classNames from 'classnames';
import * as yup from 'yup';


import styles from './MdtOnboardingDocumentUploadField.module.scss';
import Button from '../../../../../components/button/Button';
import UserService from '../../../../../services/api/UserService';
import S3Service from '../../../../../services/s3/S3Service';

export const onboardingDocumentValidation = yup
  .mixed()
  .required('A file is required')
  .test('fileSize', 'Files size is too large, it should be less than 3 MB', (value) => value === null || !value.size || value.size <= 3_000_000)
  .test('type', 'Unsupported file type', (value) => value === null || !value.size || ['application/pdf', 'image/jpeg', 'image/png'].includes(value.type));

export const uploadOnboardingDocument = async (document, type) => {
  if (document instanceof File) {
    const { body } = await UserService.getOnboardingDocumentUploadUrl({ name: document.name, type });
    await S3Service.signedUrlDocumentUpload(body.url,  document);
  }
}

export default function MdtOnboardingDocumentUploadField(props) {
  const [documentName, setDocumentName] = useState(
    props.uploaded?.name || props.uploaded?.split('/')?.slice(-1) || null,
  );

  const onChange = (event) => {
    props.setFieldValue(props.name, event.currentTarget.files[0]);

    setDocumentName(event.currentTarget.files[0].name);
  };

  const title = documentName ? 'Attached' : props.buttonLabel;
  return (
    <div className={styles.documentUploadSection}>
      <div>
        <Typography>{props.description}</Typography>
        <FormControl error={props.error}>
          <Button
            type=""
            disabled={props.disabled}
            component="label"
            className={classNames(styles.button, { [styles.errorButtonState]: props.error })}
            startIcon={documentName ? <CloudDoneIcon /> : <CloudUploadIcon />}
          >
            <span className={styles.fileUploadTitle}>{!props.loading ? title : ''}</span>
            <input
              className={styles.fileUploadInput}
              name={props.name}
              type="file"
              accept="image/jpeg,image/png,application/pdf"
              onChange={onChange}
              onBlur={props.onBlur}
            />
          </Button>
          <FormHelperText className={styles.helperText}>
            {(props.error && props.errorHelperText) || documentName ||  'Please upload PDF, PNG or JPEG'}
          </FormHelperText>
        </FormControl>
      </div>
      <FormHelperText className={styles.documentUploadSectionHelper}>{props.helperText}</FormHelperText>
    </div>
  );
}
