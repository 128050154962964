import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import ThreadsService from '../../../services/api/ThreadsService';
import { User } from '../../../types/User';
import { RootState } from '../../store';
import { ThreadMessage } from '../../../types/Thread';
import { ThreadReviewStatus } from '../../../types/enums/ThreadReviewStatus';

export const getThread = createAsyncThunk(
  `${SliceName.THREAD}/getThread`,
  async ({ id }: { id: string; isPolling?: boolean }) => {
    const thread = await ThreadsService.getById(id);

    return thread;
  },
);

export const updateThread = createAsyncThunk(
  `${SliceName.THREAD}/updateThread`,
  async ({ id, title, text }: { id: string; title: string; text: string }) => {
    await ThreadsService.updateThread(id, title, text);

    return { id, title, text };
  },
);

export const deleteThread = createAsyncThunk(`${SliceName.THREAD}/deleteThread`, async (id: string) => {
  await ThreadsService.deleteThread(id);
});

export const addUserToThread = createAsyncThunk(
  `${SliceName.THREAD}/addUserToThread`,
  async ({ threadId, user }: { threadId: string; user: User }) => {
    await ThreadsService.addUserToThread(threadId, user.id);
  },
);

export const removeUserFromThread = createAsyncThunk(
  `${SliceName.THREAD}/removeUserFromThread`,
  async ({ threadId, userId }: { threadId: string; userId: string }) => {
    await ThreadsService.removeUserFromThread(threadId, userId);
  },
);

export const addThreadReview = createAsyncThunk<
  {
    threadId: string;
    message: ThreadMessage;
  },
  { threadId: string; text: string; reviewStatus: ThreadReviewStatus },
  {
    state: RootState;
  }
>(`${SliceName.THREAD}/addThreadReview`, async ({ threadId, text, reviewStatus }, { getState }) => {
  const { id } = await ThreadsService.addReview(threadId, { text, reviewStatus });

  const user = getState().profile.profile!;

  const message: ThreadMessage = {
    id,
    text,
    user,
    type: 'REVIEW',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    reviewStatus,
    threadId,
  };

  return { threadId, message };
});

export const addThreadMessage = createAsyncThunk<
  {
    threadId: string;
    message: ThreadMessage;
  },
  { threadId: string; text: string },
  {
    state: RootState;
  }
>(`${SliceName.THREAD}/addMessage`, async ({ threadId, text }, { getState }) => {
  const { id } = await ThreadsService.addMessage(threadId, text);

  const user = getState().profile.profile!;

  const message: ThreadMessage = {
    id: id,
    text,
    user,
    type: 'PUBLIC',
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
    threadId,
  };

  return { threadId, message };
});

export const editThreadMessage = createAsyncThunk(
  `${SliceName.THREAD}/editThreadMessage`,
  async ({ messageId, threadId, text }: { messageId: string; threadId: string; text: string }) => {
    await ThreadsService.updateMessage(messageId, text);

    const messageUpdates = {
      text,
      updatedAt: new Date().toISOString(),
    };

    return { messageId, threadId, messageUpdates };
  },
);

export const deleteThreadMessage = createAsyncThunk(
  `${SliceName.THREAD}/deleteThreadMessage`,
  async ({ messageId, threadId }: { messageId: string; threadId: string }) => {
    await ThreadsService.deleteMessage(messageId);

    return { messageId, threadId };
  },
);
