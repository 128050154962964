import React from 'react';

import Services from '../landing/components/Services';
import HowItWorks from '../regenerative-medicine/components/HowItWorks/HowItWorks';
import Partners from '../landing/components/Partners';
import ContactUs from '../landing/components/ContactUs';
import styles from './Psychedelics.module.scss';
import HomeWithTitleAndBackground from '../../../components/landings/HomeWithTitleAndBackground/HomeWithTitleAndBackground';

enum LandingSectionIds {
  services = 'services',
  howItWorks = 'how-it-works',
  contactUs = 'contact-us',
}

const PsychedelicsPage: React.FC = () => {
  return (
    <div>
      <HomeWithTitleAndBackground
        className={styles.home}
        title={
          <>
            The Communication Hub for <em>Psychedelic Experts</em>
          </>
        }
        description={
          <>
            Connect and Collaborate
            <p />
            For Researchers and Academics: Share findings, explore interdisciplinary collaborations, and access a
            network of peer support and resources.
            <p />
            For Healthcare Professionals: Stay informed about the latest clinical trials, therapy protocols, and patient
            outcomes.
            <p />
            For Industry Leaders: Engage with innovations and developments that shape the future of psychiatric and
            psychological treatments.
          </>
        }
      />

      <Services id={LandingSectionIds.services} className={styles.services} />

      <HowItWorks id={LandingSectionIds.howItWorks} />

      <Partners />

      <ContactUs id={LandingSectionIds.contactUs} />
    </div>
  );
};

export default PsychedelicsPage;
