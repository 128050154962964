import React, { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import styles from './AdminPendingApplicationsPage.module.scss';
import UserService from '../../../services/api/UserService';
import Search from '../../../components/search/Search';
import { debounce } from '../../../utils/debounce';
import { getUsersSearchParams } from '../../../utils/userSearch';
import { config } from '../../../configs/config';
import Pagination from '../../../components/pagination/Pagination';
import { UserStatus } from '../../../constants/user.constants';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import { useLoader } from '../../../hooks/useLoader';

export default function AdminPendingApplicationsPage() {
  const [users, setUsers] = useState([]);
  const [page, setPage] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const loader = useLoader();

  useEffect(() => {
    const fetchInitialData = async () => {
      loader.startLoading();

      const { body, headers } = await UserService.getUsers({
        status: UserStatus.PENDING,
        embed: ['address', 'mdtProfile'].join(','),
        limit: config.pagination.usersPageSize,
        offset: (page - 1) * config.pagination.usersPageSize,
      });
      setUsers(body);
      setTotalUsers(headers['x-total-count']);
      loader.stopLoading();
    };

    fetchInitialData();
  }, [page]);

  const onSearchChange = async (event) => {
    const term = event.target.value;

    setPage(1);
    setSearch(term);

    const { body } = await UserService.getUsers({
      status: UserStatus.PENDING,
      embed: ['address', 'mdtProfile'].join(','),
      limit: config.pagination.usersPageSize,
      offset: (page - 1) * config.pagination.usersPageSize,
      ...getUsersSearchParams(term),
    });
    setUsers(body);
  };

  const debouncedOnSearchChange = debounce(onSearchChange, 1000);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box component="main" className={styles.wrapper}>
      <div>
        <Box className={styles.actions}>
          <div className={styles.search}>
            <Search
              placeholder="Search for a user"
              value={search}
              onChange={(event) => debouncedOnSearchChange(event)}
            />
          </div>
        </Box>

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>First Name</TableCell>
                <TableCell>Last Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Submitted At</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <TableRow key={user.id} onClick={() => navigate(`/internal/admin/users/${user.id}`)}>
                  <TableCell className={styles.tableCell}>{user.id}</TableCell>
                  <TableCell className={styles.tableCell}>{user.firstName}</TableCell>
                  <TableCell className={styles.tableCell}>{user.lastName}</TableCell>
                  <TableCell className={styles.tableCell}>{user.email}</TableCell>
                  <TableCell className={styles.tableCell}>
                    {dayjs(user.mdtProfile?.updatedAt).format('ddd, MMM DD YYYY H:mm A')}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className={styles.pagination}>
        <Pagination
          count={Math.ceil(totalUsers / config.pagination.usersPageSize)}
          page={parseInt(page)}
          onChange={(event, value) => handlePageChange(event, value)}
        />
      </div>
    </Box>
  );
}
