import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './GroupsMenuHeader.module.scss';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';

import { hasPermission } from '../../../utils/permissions';
import { MdtPermissions } from '../../../constants/user.constants';

export default function GroupsMenuHeader({ permissions, title, setCreateGroupModal, groups }) {
  const getTitle = () => {
    if (!groups) {
      return <Skeleton variant="rounded" height={20} width={120} />;
    }

    return <Typography>{title}</Typography>;
  };

  const getButton = () => {
    if (!groups || !hasPermission(permissions, MdtPermissions.CREATE_GROUP)) {
      return;
    }

    return (
      <IconButton onClick={() => setCreateGroupModal(true)}>
        <AddCircleIcon />
      </IconButton>
    );
  };
  return (
    <div className={styles.header}>
      {getTitle()}
      {getButton()}
    </div>
  );
}
