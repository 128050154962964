import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';
import { Profile } from '../../types/Profile';
import { OnboardingProfile } from '../../types/OnboardingProfile';
import { UpdateProfileDTO } from '../../types/DTOs/UpdateProfileDTO';

class ProfileService {
  async getProfile() {
    const response = await ApiClient.get<Profile>(ApiRoute.PROFILE);

    return getResponseData(response);
  }

  async getOnboardingProfile() {
    const response = await ApiClient.get<OnboardingProfile>(ApiRoute.ONBOARDING_PROFILE);

    return getResponseData(response);
  }

  async updateProfile({ id, updateProfileDTO }: { id: string; updateProfileDTO: UpdateProfileDTO }) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.USERS}/${id}`, updateProfileDTO);

    return getResponseData(response);
  }
}

export default new ProfileService();
