import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import classNames from 'classnames';

import GroupCreateModal from '../../pages/private/groups/components/modals/GroupCreateModal';
import GroupsMenuSearch from './components/GroupsMenuSearch';
import GroupsMenuHeader from './components/GroupsMenuHeader';
import GroupsMenuList from './components/GroupsMenuList';
import styles from './GroupsMenu.module.scss';
import { GroupType } from '../../types/enums/GroupType';
import { OrganisationCategory } from '../../types/enums/OrganisationCategory';
import { useIsMobile } from '../../hooks/useIsMobile';

export default function GroupsMenu({ permissions, groups, title, type, hideOnMobile }) {
  const { groupId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const [createGroupModal, setCreateGroupModal] = useState(false);
  const [filteredGroups, setFilteredGroups] = useState(null);
  const [search, setSearch] = useState('');

  useEffect(() => {
    groups && setFilteredGroups(groups.filter((group) => group.name.toLowerCase().includes(search.toLowerCase())));
  }, [search, groups]);

  useEffect(() => {
    // TODO: Remove this check after groups state is moved to store and cleared correctly
    const isCorrectGroupType = groups?.every((group) => group.type === type);

    if (type === GroupType.MDT && !groupId && groups?.length && isCorrectGroupType && !isMobile) {
      const firstPrivateGroup = groups.find((group) => group.category === OrganisationCategory.PRIVATE);
      const firstSharedGroup = groups.find((group) => group.category === OrganisationCategory.SHARED);

      if (firstPrivateGroup) navigate(`${pathname}/${firstPrivateGroup.id}`);
      else if (firstSharedGroup) navigate(`${pathname}/${firstSharedGroup.id}`);
    }
  }, [groupId, type, groups]);

  return (
    <Box variant="permanent" className={classNames(styles.groupsMenu, hideOnMobile && styles.hiddenOnMobile)}>
      <Box>
        <GroupsMenuHeader
          groups={groups}
          permissions={permissions}
          title={title}
          setCreateGroupModal={setCreateGroupModal}
        />
        <GroupsMenuSearch groups={groups} search={search} onSearch={(event) => setSearch(event.target.value)} />
        <GroupsMenuList search={search} groups={groups} filteredGroups={filteredGroups} type={type.toLowerCase()} />
      </Box>

      <GroupCreateModal createGroupModal={createGroupModal} setCreateGroupModal={setCreateGroupModal} type={type} />
    </Box>
  );
}
