import { useFeedback } from '../../../../../hooks/useFeedback';
import { OnboardingProfile } from '../../../../../types/OnboardingProfile';

export const useValidateOnboardingProfile = () => {
  const { showErrorFeedback } = useFeedback();

  const handleValidate = (profile: OnboardingProfile | null) => {
    try {
      if (!profile) throw new Error('No profile');

      const { address, identity, qualityCredential } = profile;

      if (!address.addressLine1 || !address.city || !address.region || !address.zipCode) {
        throw new Error('Please complete Place of Registration/Medical Practise step');
      }

      if (!identity.document) {
        throw new Error('Please complete Identity Verification step');
      }

      if (!qualityCredential.document && !qualityCredential.medicalRegistrationNumber) {
        throw new Error('Please complete Credential Certificates step');
      }

      if (!profile.annualSalary.latestAppraisalDate) {
        throw new Error('Please complete Recent Annual Appraisal step');
      }

      if (!profile.criminalBackground.document) {
        throw new Error('Please complete Criminal Background Check step');
      }

      return true;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      showErrorFeedback(error.message);

      return false;
    }
  };

  return handleValidate;
};
