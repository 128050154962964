import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import styles from './DasboardStats.module.scss';
import MdtService from '../../../../services/api/MdtService';
import Skeleton from '@mui/material/Skeleton';
import RecommendIcon from '@mui/icons-material/Recommend';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EmailIcon from '@mui/icons-material/Email';
import CategoryIcon from '@mui/icons-material/Category';

export default function DasboardStats() {
  const [dashboard, setDashboard] = useState(null);

  useEffect(() => {
    const fetchDashboard = async () => {
      const { body } = await MdtService.getDashboard();
      setDashboard(body);
    };
    fetchDashboard().catch((error) => console.error(error));
  }, []);

  const displayCardItem = (title, value, icon) => (
    <div className={styles.containerItem}>
      <div className={styles.containerItemLabel}>
        <div>{title}</div>
        <div className={styles.containerItemLabelIcon}>{icon}</div>
      </div>
      <div className={styles.containerItemNumber}>
        {typeof value === 'number' ? value : <Skeleton variant="rounded" height="1.5rem" width="2rem" />}
      </div>
    </div>
  );

  return (
    <Grid className={styles.container} container direction="column" justify="center">
      {displayCardItem(
        'Threads Created',
        dashboard?.threadsCreated,
        <CategoryIcon sx={{ color: '#f49d0a' }} />,
        '#f49d0a',
      )}
      {displayCardItem('Messages Sent', dashboard?.messagesSent, <EmailIcon sx={{ color: '#0c7fda' }} />, '#0c7fda')}
      {displayCardItem(
        'Threads Membership',
        dashboard?.threadsMembership,
        <Diversity3Icon sx={{ color: '#667a8a' }} />,
        '#667a8a',
      )}
      {displayCardItem(
        'Approved Cases',
        dashboard?.approvedMdtCases,
        <RecommendIcon sx={{ color: '#89c2ad' }} />,
        '#89c2ad',
      )}
    </Grid>
  );
}
