export enum SliceName {
  AUTH = 'AUTH',
  PROFILE = 'PROFILE',
  ONBOARDING_PROFILE = 'ONBOARDING_PROFILE',
  FEEDBACK = 'FEEDBACK',
  ORGANISATIONS = 'ORGANISATIONS',
  ORGANISATION_USERS = 'ORGANISATION_USERS',
  GROUPS = 'GROUPS',
  THREADS = 'THREADS',
  THREAD = 'THREAD',
  MULTIFACTOR_AUTH_SETTINGS = 'MULTIFACTOR_AUTH_SETTINGS',
  USER_DETAILS = 'USER_DETAILS',
  USER_NOTIFICATION_SETTINGS = 'USER_NOTIFICATION_SETTINGS',
}
