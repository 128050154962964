import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';

import Typography from '@mui/material/Typography';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Skeleton from '@mui/material/Skeleton';

import styles from './ThreadHeader.module.scss';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ThreadEditModal from '../modals/ThreadEditModal';
import { hasPermission } from '../../../../../utils/permissions';
import { MdtPermissions } from '../../../../../constants/user.constants';
import Button from '../../../../../components/button/Button';
import { ThreadEditTitleNameMap } from '../../../../../constants/thread.constants';
import DownloadThreadButton from './components/DownloadThreadButton/DownloadThreadButton';
import PrintThreadButton from './components/PrintThreadButton/PrintThreadButton';

export default function ThreadHeader({ permissions, group, thread, isLoading }) {
  const [editThreadModal, setEditThreadModal] = useState(false);

  const navigate = useNavigate();
  const { groupId } = useParams();
  const location = useLocation();

  const handleBack = () => navigate(`/internal/groups/${group?.type?.toLowerCase()}/${groupId}${location.search}`);

  const actionButtons = (
    <>
      <div className={styles.buttonsWrapper}>
        {hasPermission(permissions, MdtPermissions.DOWNLOAD_THREAD) && <DownloadThreadButton thread={thread} />}
        {hasPermission(permissions, MdtPermissions.PRINT_THREAD) && (
          <PrintThreadButton groupId={group.id} thread={thread} />
        )}
        {hasPermission(permissions, MdtPermissions.EDIT_THREAD) && (
          <Button rounded className={styles.button} onClick={() => setEditThreadModal(true)} variant="contained">
            {ThreadEditTitleNameMap[group.type]}
          </Button>
        )}
      </div>

      <ThreadEditModal
        permissions={permissions}
        editThreadModal={editThreadModal}
        setEditThreadModal={setEditThreadModal}
        group={group}
        thread={thread}
      />
    </>
  );

  if (isLoading || !thread) {
    return (
      <div className={styles.threadHeaderWrapper}>
        <div className={styles.threadHeaderInfo}>
          <IconButton onClick={handleBack}>
            <ChevronLeftIcon />
          </IconButton>

          <Typography className={styles.threadHeaderName}>
            <Skeleton variant="rounded" height={20} width={300} />
          </Typography>
        </div>

        <div className={styles.threadHeaderButtons} />
      </div>
    );
  }

  return (
    <div className={styles.threadHeaderWrapper}>
      <div className={styles.threadHeaderInfo}>
        <IconButton onClick={handleBack}>
          <ChevronLeftIcon />
        </IconButton>

        <Typography className={styles.threadHeaderName}>{thread.title}</Typography>
      </div>

      <div className={styles.threadHeaderButtons}>{actionButtons}</div>
    </div>
  );
}
