import Content from '../components/content/Content';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { MdtProvider } from '../context/MdtContext';
import { useEffect } from 'react';
import PrivateNavigation from '../components/private-navigation/PrivateNavigation';

export default function ProtectedLayout() {
  const auth = useAuthContext();

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (!auth.isAuthenticated()) {
    return <Navigate to="/" />;
  }

  if (auth.isOnboarding && !pathname.includes('onboarding') && !pathname.includes('profile')) {
    return <Navigate to="/onboarding" replace />;
  }

  const displayMdtContent = () => {
    return (
      <MdtProvider>
        <PrivateNavigation>
          <Outlet />
        </PrivateNavigation>
      </MdtProvider>
    );
  };

  return (
    <>
      <Content>{auth.user && displayMdtContent()}</Content>
    </>
  );
}
