import React, { useMemo, useState } from 'react';
import WysiwygEditor from '../../../../../components/wysiwyg-editor/WysiwygEditor';
import * as yup from 'yup';
import styles from './ThreadEditModal.module.scss';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useFormik } from 'formik';
import { useMdtContext } from '../../../../../hooks/useMdtContext';
import Modal from '../../../../../components/modal/Modal';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import ModalButton from '../../../../../components/modal-button/ModalButton';
import { convertEditorStateToHtml, convertHtmlToEditorState } from '../../../../../utils/editor';
import { hasPermission } from '../../../../../utils/permissions';
import { MdtPermissions } from '../../../../../constants/user.constants';
import ConfirmDialog from '../../../../../components/confirm-dialog/ConfirmDialog';
import { ThreadAttachmentOperation, ThreadEditTitleNameMap } from '../../../../../constants/thread.constants';
import ThreadAttachFile from './components/ThreadAttachFile';
import MdtService from '../../../../../services/api/MdtService';
import S3Service from '../../../../../services/s3/S3Service';
import { deleteThread, updateThread } from '../../../../../store/slices/thread/threadThunks';
import { useAppDispatch } from '../../../../../store/hooks';

const editThreadValidationSchema = yup.object({
  title: yup
    .string()
    .min(5, 'Min title size 5 characters')
    .max(280, 'Max title size 280 characters')
    .required('Title field is required'),
  text: yup
    .string()
    .transform((value) => value.getCurrentContent().getPlainText())
    .max(3000, 'Max text size 3000 characters'),
  attachments: yup
    .array()
    .max(25, `No more than 25 attachments are allowed`)
    .of(
      yup
        .mixed()
        .nullable()
        .test('fileSize', 'Attachment size should be less than 3 MB', ({ file }) => {
          return file === null || !file?.size || file?.size <= 3_000_000;
        })
        .test('type', 'Unsupported Format', ({ file }) => {
          return file === null || !file?.size || ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type);
        }),
    ),
});

export default function ThreadEditModal({ thread, editThreadModal, permissions, setEditThreadModal, group }) {
  const dispatch = useAppDispatch();

  const [isDeleting, setIsDeleting] = useState(false);
  const [deleteConfirmDialog, setDeleteConfirmDialog] = useState(false);

  const { onThreadDeleted, onThreadUpdated } = useMdtContext();
  const text = useMemo(() => convertHtmlToEditorState(thread.text), [thread.text]);

  const updateThreadAttachment = async (attachment) => {
    if (attachment.operation === ThreadAttachmentOperation.ADD) {
      const { body } = await MdtService.getThreadAttachmentUploadSignature(thread.id, { name: attachment.url });
      await S3Service.signedUrlDocumentUpload(body.url, attachment.file);
    }

    if (attachment.operation === ThreadAttachmentOperation.REMOVE) {
      await MdtService.deleteThreadAttachment(thread.id, { url: attachment.url });
    }
  };

  const updateThreadAttachments = async (attachments) => {
    await Promise.all(attachments.map((attachment) => updateThreadAttachment(attachment)));
  };

  const onSubmit = async (values) => {
    try {
      await updateThreadAttachments(values.attachments);
      const text = convertEditorStateToHtml(values.text);

      await dispatch(
        updateThread({
          id: thread.id,
          title: values.title,
          text,
        }),
      ).unwrap();

      onThreadUpdated(thread.id, {
        title: values.title,
        text,
      });

      onClose();
    } catch {}
  };

  const threadFormik = useFormik({
    initialValues: { title: thread.title, text, attachments: thread.attachments },
    validationSchema: editThreadValidationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const onDelete = async () => {
    setDeleteConfirmDialog(true);
  };

  const onDeleteConfirm = async () => {
    try {
      setDeleteConfirmDialog(false);

      setIsDeleting(true);

      await dispatch(deleteThread(thread?.id)).unwrap();

      setEditThreadModal(false);

      onThreadDeleted(thread?.id);
    } catch {
    } finally {
      setIsDeleting(false);
    }
  };

  const onClose = () => {
    setEditThreadModal(false);
    threadFormik.resetForm();
  };

  return (
    <>
      <Modal open={editThreadModal} onClose={onClose}>
        <form component="form" onSubmit={threadFormik.handleSubmit} fullWidth>
          <ModalTitle>{ThreadEditTitleNameMap[group.type]}</ModalTitle>

          <TextInputField label="Group Name" disabled={true} value={group.name} />

          <TextInputField
            label="Title"
            id="title"
            name="title"
            value={threadFormik.values.title}
            onChange={threadFormik.handleChange}
            onBlur={threadFormik.handleBlur}
            error={threadFormik.touched.title && Boolean(threadFormik.errors.title)}
            errorHelperText={threadFormik.errors.title}
          />

          <WysiwygEditor
            id="text"
            name="text"
            editorState={threadFormik.values.text}
            onEditorStateChange={(value) => threadFormik.setFieldValue('text', value)}
            error={threadFormik.touched.text && Boolean(threadFormik.errors.text)}
            errorHelperText={threadFormik.errors.text}
          />

          <ThreadAttachFile
            onChange={(value) => threadFormik.setFieldValue('attachments', value)}
            attachments={threadFormik.values.attachments}
            error={threadFormik.touched.attachments && threadFormik.errors.attachments}
          />

          <div className={styles.buttons}>
            <ModalButton className={styles.deleteButton} loading={isDeleting} onClick={onDelete}>
              Remove
            </ModalButton>

            {hasPermission(permissions, MdtPermissions.EDIT_THREAD) && (
              <ModalButton className={styles.editButton} loading={threadFormik.isSubmitting} type="submit">
                Edit
              </ModalButton>
            )}
          </div>
        </form>
      </Modal>

      <ConfirmDialog
        open={deleteConfirmDialog}
        text="Are you sure you want to remove the thread?"
        onClose={() => setDeleteConfirmDialog(false)}
        onConfirm={onDeleteConfirm}
      />
    </>
  );
}
