import { combineReducers, configureStore } from '@reduxjs/toolkit';

import authReducer from './slices/auth/authSlice';
import profileReducer from './slices/profile/profileSlice';
import onboardingProfileReducer from './slices/onboardingProfile/onboardingProfileSlice';
import feedbackReducer from './slices/feedback/feedbackSlice';
import organisationsReducer from './slices/organisations/organisationsSlice';
import organisationUsersReducer from './slices/organisationUsers/organisationUsersSlice';
import threadReducer from './slices/thread/threadSlice';
import multifactorAuthSettingsReducer from './slices/multifactorAuthSettings/multifactorAuthSettingsSlice';
import userDetailsReducer from './slices/userDetails/userDetailsSlice';
import userNotificationSettingsReducer from './slices/userNotificationSettings/userNotificationSettingsSlice';

import { signOut } from './slices/auth/authThunks';

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  onboardingProfile: onboardingProfileReducer,
  feedback: feedbackReducer,
  organisations: organisationsReducer,
  organisationUsers: organisationUsersReducer,
  thread: threadReducer,
  multifactorAuthSettings: multifactorAuthSettingsReducer,
  userDetails: userDetailsReducer,
  userNotificationSettings: userNotificationSettingsReducer,
});

export const store = configureStore({
  reducer: (state: Parameters<typeof rootReducer>[0], action: Parameters<typeof rootReducer>[1]) => {
    if (action.type === signOut.fulfilled.type) {
      state = undefined;
    }

    return rootReducer(state, action);
  },
  // devTools: true,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
