import React from 'react';
import WysiwygEditor from '../../../../../components/wysiwyg-editor/WysiwygEditor';

import styles from './ThreadEditor.module.scss';
import Button from '../../../../../components/button/Button';

export default function ThreadEditor({ formik, isEdit, error, errorHelperText }) {
  return (
    <form className={styles.threadEditorWrapper} onSubmit={formik.handleSubmit}>
      <div>
        <WysiwygEditor
          id="text"
          name="text"
          editorState={formik.values.text}
          error={error}
          errorHelperText={errorHelperText}
          onEditorStateChange={(value) => formik.setFieldValue('text', value)}
        />
      </div>
      <div className={styles.threadEditorButton}>
        <Button className={styles.button} fullWidth loading={formik.isSubmitting}>
          {isEdit ? 'Edit Message' : 'Send Message'}
        </Button>
      </div>
    </form>
  );
}
