export const config = {
  appName: 'MedSym',
  apiUrl: 'https://api.medsym.io',
  pagination: {
    threadsPageSize: 10,
    usersPageSize: 15,
    demosPageSize: 10,
    contactUsRequestsPageSize: 10,
  },
  httpPolling: {
    groups: {
      enabled: true,
      interval: 30000,
    },
    group: {
      enabled: true,
      interval: 20000,
    },
    thread: {
      enabled: true,
      interval: 10000,
    },
  },
  googleTagId: 'GTM-TT84J7CG',
  isNative: false,
};
