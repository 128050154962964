import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';

import { useNavigate } from 'react-router-dom';

import styles from './Account.module.scss';
import Chip from '../../../../components/chips/Chip/Chip';
import { ChangeEvent, useEffect, useState } from 'react';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { UserStatusMap } from '../../../../constants/user.constants';
import UserProfileWrapper from '../../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../../components/user-profile/UserProfileItem';
import Button from '../../../../components/button/Button';
import SidebarSignoutIcon from '../../../../components/private-navigation/sidebar/components/icons/SidebarSignoutIcon.js';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { signOut } from '../../../../store/slices/auth/authThunks';
import SelectInputField from '../../../../components/select-input-field/SelectInputField';
import { countries, specialities } from '../../../public/sign-up/sign-up-form/SignUpForm.constants';
import { selectUserDetails, setUserId } from '../../../../store/slices/userDetails/userDetailsSlice';
import { getUserDetails, updateUserDetails } from '../../../../store/slices/userDetails/userDetailsThunks';
import { User } from '../../../../types/User';

const Account: React.FC = () => {
  const dispatch = useAppDispatch();

  const auth = useAuthContext() as { user: User };
  const [logoutLoading, setLogoutLoading] = useState(false);
  

  const [isAddressEditing, setIsAddressEditing] = useState(false);
  const [isSpecialityEditing, setSpecialityEditing] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedSpeciality, setSelectedSpeciality] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    if (!auth?.user?.id) return

    dispatch(setUserId(auth.user.id));
    dispatch(getUserDetails());
  }, []);


  const { userDetails: user } = useAppSelector(selectUserDetails);


  if (!user) {
    return
  }

  const handleSignOut = async () => {
    try {
      setLogoutLoading(true);

      await dispatch(signOut()).unwrap();

      setLogoutLoading(false);
      navigate('/');
    } catch (error) {
      // ignore error
    }
  };

  const onAddressSave = async () => {
    if (!selectedCountry) return;

    setIsAddressEditing(false);

    await dispatch(updateUserDetails({ address: { country: selectedCountry } })).unwrap();
    await dispatch(getUserDetails()).unwrap();
  }

  const onSpecialitySave = async () => {
    if (!selectedSpeciality) return;

    setSpecialityEditing(false)

    await dispatch(updateUserDetails({ speciality: selectedSpeciality })).unwrap();
    await dispatch(getUserDetails()).unwrap();
  }

  const renderUserProfileAddressItem = () => {
    const { country, city, zipCode, addressLine1, addressLine2 } = user.address || {};
    if (isAddressEditing) {
      return (
        <SelectInputField
          name="country"
          className={styles.selectInput}
          value={user?.address?.country}
          defaultValue={user?.address?.country}
          onChange={(e:  ChangeEvent<HTMLInputElement>) => setSelectedCountry(e.target.value)}
        >
          {countries.map((country) => (<MenuItem key={country} value={country}>{country}</MenuItem>))}
        </SelectInputField>
      )
    }
    return [addressLine2, addressLine1, city, zipCode, country].filter((item) => item).join(', ');
  }

  const renderUserProfileSpecialityItem = () => {
    if (isSpecialityEditing) {
      return (
        <SelectInputField
          name="speciality"
          className={styles.selectInput}
          value={user.speciality}
          defaultValue={user.speciality}
          onChange={(e: ChangeEvent<HTMLInputElement>) => setSelectedSpeciality(e.target.value)}
        >
          {specialities.map((specialty) => (<MenuItem key={specialty} value={specialty.toLowerCase()}>{specialty}</MenuItem>))}
        </SelectInputField>
      )
    }

    return (
      <Chip size="small" color="primary" label={user.speciality || 'N/A'} />
    )
  }

  return (
    <UserProfileWrapper title="Account" description="Your account settings">
      <UserProfileItem title="Name">
        <div className={styles.profile}>
          <Avatar>
            {user?.firstName && user?.firstName[0]}
            {user?.lastName && user?.lastName[0]}
          </Avatar>
          <Typography>
            {user.firstName} {user.lastName}
          </Typography>
          {/* @ts-expect-error todo: update status map */}
          {user.status && <Chip label={UserStatusMap[user.status].label} color={UserStatusMap[user.status].color} />}
        </div>
      </UserProfileItem>

      <UserProfileItem title="Email address">
        <div className={styles.item}>{user.email}</div>
      </UserProfileItem>

      <UserProfileItem title="Phone number">
        <div className={styles.item}>{user.phoneNumber}</div>
      </UserProfileItem>

      <UserProfileItem
        title="Place of Registration/Medical Practise"
        showActions={!user.address?.country || user.address?.country === '–'}
        onEdit={() => setIsAddressEditing(true)}
        onClose={() => setIsAddressEditing(false)}
        onSave={onAddressSave}
      >
        <div className={styles.item}>
          {renderUserProfileAddressItem()}
        </div>
      </UserProfileItem>

      <UserProfileItem
        title="Speciality"
        showActions={!user.speciality || user.speciality === 'other'}
        onEdit={() => setSpecialityEditing(true)}
        onClose={() => setSpecialityEditing(false)}
        onSave={onSpecialitySave}
      >
        <div className={styles.item}>
          {renderUserProfileSpecialityItem()}
        </div>
      </UserProfileItem>

      <UserProfileItem title="Medical registration number">
        <div className={styles.item}>{user?.mdtProfile?.medicalRegistrationNumber}</div>
      </UserProfileItem>

      <Button
        // size="small"
        fullWidth
        disabled={logoutLoading}
        loading={logoutLoading}
        className={styles.logoutButton}
        onClick={handleSignOut}
      >
        <SidebarSignoutIcon />
        Logout
      </Button>
    </UserProfileWrapper>
  );
}

export default Account;
