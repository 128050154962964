import { createSlice } from '@reduxjs/toolkit';

import type { RootState } from '../../store';
import { SliceName } from '../../config';
import { confirmMultifactorAuth, signIn } from './authThunks';

interface AuthState {
  multifactorVerificationData: {
    email: string;
    session: string;
  } | null;
  error: string | null | undefined;
  loading: boolean;
}

const initialState: AuthState = {
  multifactorVerificationData: null,
  loading: false,
  error: null,
};

const authSlice = createSlice({
  name: SliceName.AUTH,
  initialState,
  reducers: {
    resetMultifactorVerificationData: (state) => {
      state.multifactorVerificationData = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(signIn.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(signIn.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload?.multifactorVerificationData) {
        state.multifactorVerificationData = action.payload.multifactorVerificationData;
      } else {
        state.multifactorVerificationData = null;
      }
    });

    builder.addCase(signIn.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });

    builder.addCase(confirmMultifactorAuth.pending, (state) => {
      state.loading = true;
      state.error = null;
    });

    builder.addCase(confirmMultifactorAuth.fulfilled, (state) => {
      state.loading = false;
      state.multifactorVerificationData = null;
    });

    builder.addCase(confirmMultifactorAuth.rejected, (state, action) => {
      state.error = action.error.message;
      state.loading = false;
    });
  },
});

export const { resetMultifactorVerificationData } = authSlice.actions;

export const selectAuth = (state: RootState) => state.auth;

export default authSlice.reducer;
