import React from 'react';

import styles from './MdtThreadReviewEditor.module.scss';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import WysiwygEditor from '../../../../components/wysiwyg-editor/WysiwygEditor';
import { MessageStatus, MessageType } from '../../../../constants/message.constants';
import Button from '../../../../components/button/Button';

function ReviewButton({ isEdit, formik }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const options = [
    { type: MessageType.DEFAULT, title: isEdit ? 'Edit Message' : 'Send Message' },
    { type: MessageStatus.CHANGES_REQUESTED, title: 'Request Changes' },
    { type: MessageStatus.APPROVED, title: 'Approve' },
    { type: MessageStatus.REJECTED, title: 'Reject' },
  ];

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup className={styles.buttonGroup} variant="contained" aria-label="split button">
        <Button
          loading={formik.isSubmitting}
          className={styles.actionButton}
          refAnchor={anchorRef}
          onClick={() => formik.setFieldValue('type', options[selectedIndex].type)}
        >
          {options[selectedIndex].title}
        </Button>
        {
          <Button
            size="small"
            disabled={formik.isSubmitting}
            className={styles.optionButton}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevOpen) => !prevOpen);
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        }
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 1, width: 'max-content' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
              width: anchorRef.current ? anchorRef.current.offsetWidth : null,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.type}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      {option.title}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}

export default function MdtThreadReviewEditor({ isEdit, formik, error, errorHelperText }) {
  return (
    <form className={styles.threadEditorWrapper} onSubmit={formik.handleSubmit}>
      <div>
        <WysiwygEditor
          id="text"
          name="text"
          editorState={formik.values.text}
          error={error}
          errorHelperText={errorHelperText}
          onEditorStateChange={(value) => formik.setFieldValue('text', value)}
        />
      </div>
      <div className={styles.threadEditorButton}>
        <ReviewButton formik={formik} isEdit={isEdit} />
      </div>
    </form>
  );
}
