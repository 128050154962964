import React from 'react';

import Home from './components/Home/Home';
import Services from '../landing/components/Services';
import HowItWorks from './components/HowItWorks/HowItWorks';
import Partners from '../landing/components/Partners';
import ContactUs from '../landing/components/ContactUs';
import styles from './HRTPage.module.scss';

enum LandingSectionIds {
  home = 'home',
  services = 'services',
  howItWorks = 'how-it-works',
  contactUs = 'contact-us',
}

const HRTPage: React.FC = () => {
  return (
    <div>
      <Home id={LandingSectionIds.home} />

      <Services id={LandingSectionIds.services} className={styles.services} />

      <HowItWorks id={LandingSectionIds.howItWorks} />

      <Partners />

      <ContactUs id={LandingSectionIds.contactUs} className={styles.contactUs} />
    </div>
  );
};

export default HRTPage;
