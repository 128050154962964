import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import styles from './SidebarExpandableItem.module.scss';

const SidebarNavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function SidebarExpandableItem(props) {
  return (
    <ListItem disablePadding sx={{ display: 'block' }} component="span">
      <ListItemButton
        to={props.to}
        component={props.to && SidebarNavigationLink}
        className={styles.button}
        sx={{ justifyContent: props.open ? 'initial' : 'center', px: 2.5 }}
        onClick={(e) => e.stopPropagation()}
      >
        <ListItemText className={styles.text} primary={props.text} sx={{ opacity: props.open ? 1 : 0 }} />
      </ListItemButton>
    </ListItem>
  );
}
