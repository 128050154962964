import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { EditorState } from 'draft-js';
import * as yup from 'yup';

import ThreadHeader from '../components/thread/ThreadHeader';
import ThreadDetails from '../components/thread//ThreadDetails';
import ThreadMessages from '../components/thread/ThreadMessages';
import { useMdtContext } from '../../../../hooks/useMdtContext';
import { convertEditorStateToHtml } from '../../../../utils/editor';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { getUserRole, hasPermission } from '../../../../utils/permissions';
import { MdtPermissions, UserRole } from '../../../../constants/user.constants';
import MdtThreadSidebar from './MdtThreadSidebar';
import styles from './MdtThread.module.scss';
import MdtThreadReviewEditor from './MdtThreadReviewEditor';
import { MessageType } from '../../../../constants/message.constants';
import { ThreadRole, ThreadStatus } from '../../../../constants/thread.constants';
import ThreadEditor from '../components/thread/ThreadEditor';
import ThreadLayout from '../components/thread/ThreadLayout/ThreadLayout';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearThread, selectThread } from '../../../../store/slices/thread/threadSlice';
import {
  addThreadMessage,
  addThreadReview,
  editThreadMessage,
  getThread,
} from '../../../../store/slices/thread/threadThunks';
import { usePolling } from '../../../../hooks/usePolling';
import { config } from '../../../../configs/config';

const messageValidationSchema = yup.object({
  text: yup
    .string()
    .transform((value) => value.getCurrentContent().getPlainText())
    .max(3000, 'Max message size 3000 characters'),
});

export default function MdtThread() {
  const dispatch = useAppDispatch();

  const [editMessageId, setEditMessageId] = useState(null);
  const messagesEndRef = useRef(null);

  const params = useParams();

  const { thread, error: threadError, isLoading } = useAppSelector(selectThread);

  useEffect(() => {
    dispatch(getThread({ id: params.threadId }));

    return () => dispatch(clearThread());
  }, [params.threadId]);

  usePolling({
    callback: () => dispatch(getThread({ id: params.threadId, isPolling: true })),
    interval: config.httpPolling.thread.interval,
    skip: isLoading,
  });

  const { group } = useMdtContext();
  const { user } = useAuthContext();

  const canEdit = user.id === thread?.user?.id && !thread.messages?.length;
  const canReview = thread?.members?.find((member) => member.role === ThreadRole.REVIEWER && member.id === user.id);
  const canPrintOrDownload = thread?.members?.find((member) => member.id === user.id && [ThreadRole.REVIEWER, ThreadRole.OWNER].includes(member.role));

  const rolePermissions = {
    [UserRole.ADMIN]: [
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.EDIT_THREAD,
      MdtPermissions.DELETE_THREAD,
      MdtPermissions.READ_THREAD,
      MdtPermissions.DELETE_MESSAGE,
      MdtPermissions.CREATE_REVIEW,
      MdtPermissions.PRINT_THREAD,
      MdtPermissions.DOWNLOAD_THREAD,
    ],
    [UserRole.DEFAULT]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_THREAD,
      canEdit && MdtPermissions.EDIT_THREAD,
      canEdit && MdtPermissions.DELETE_THREAD,
      canReview && MdtPermissions.CREATE_REVIEW,
      canPrintOrDownload && MdtPermissions.PRINT_THREAD,
      canPrintOrDownload && MdtPermissions.DOWNLOAD_THREAD,
    ],
  };
  const permissions = rolePermissions[getUserRole(user.scopes)] || [];

  const formik = useFormik({
    initialValues: { text: EditorState.createEmpty() },
    validationSchema: messageValidationSchema,
    onSubmit: async (values) => {
      try {
        if (!values.text.getCurrentContent().hasText()) return;

        const markup = convertEditorStateToHtml(values.text);
        if (editMessageId) {
          await dispatch(
            editThreadMessage({
              threadId: thread.id,
              messageId: editMessageId,
              text: markup,
            }),
          ).unwrap();

          return setEditMessageId(null);
        }

        if (!values.type || values.type === MessageType.DEFAULT) {
          await dispatch(
            addThreadMessage({
              threadId: thread.id,
              text: markup,
            }),
          ).unwrap();

          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
          return;
        }

        await dispatch(
          addThreadReview({
            threadId: thread.id,
            text: markup,
            reviewStatus: values.type,
          }),
        ).unwrap();

        return messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
      } finally {
        formik.setFieldValue('text', EditorState.createEmpty());
      }
    },
  });

  return (
    <ThreadLayout
      header={<ThreadHeader group={group} thread={thread} permissions={permissions} isLoading={isLoading} />}
    >
      {threadError ? (
        <ErrorMessage>{threadError}</ErrorMessage>
      ) : (
        <>
          <div className={styles.threadContentWrapper}>
            <ThreadDetails thread={thread} dislayStatus={true} />

            <MdtThreadSidebar thread={thread} />
          </div>

          <ThreadMessages
            editPeriod={300}
            permissions={permissions}
            thread={thread}
            formik={formik}
            editMessageId={editMessageId}
            setEditMessageId={setEditMessageId}
            messagesEndRef={messagesEndRef}
          />

          {![ThreadStatus.APPROVED, ThreadStatus.REJECTED].includes(thread?.status) &&
          hasPermission(permissions, MdtPermissions.CREATE_REVIEW) ? (
            <MdtThreadReviewEditor
              isEdit={!!editMessageId}
              formik={formik}
              error={formik.touched.text && Boolean(formik.errors.text)}
              errorHelperText={formik.errors.text}
            />
          ) : (
            <ThreadEditor
              isEdit={!!editMessageId}
              formik={formik}
              error={formik.touched.text && Boolean(formik.errors.text)}
              errorHelperText={formik.errors.text}
            />
          )}
        </>
      )}
    </ThreadLayout>
  );
}
