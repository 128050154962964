import React, { useState, useEffect, useMemo } from 'react';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import styles from './ThreadAddModal.module.scss';
import { useFormik } from 'formik';
import TextInputField from '../../../../../components/text-input-field/TextInputField';
import { useMdtContext } from '../../../../../hooks/useMdtContext';
import Modal from '../../../../../components/modal/Modal';
import ModalTitle from '../../../../../components/modal-title/ModalTitle';
import ModalButton from '../../../../../components/modal-button/ModalButton';
import WysiwygEditor from '../../../../../components/wysiwyg-editor/WysiwygEditor';
import { convertEditorStateToHtml } from '../../../../../utils/editor';
import { FormHelperText, MenuItem } from '@mui/material';
import SelectInputField from '../../../../../components/select-input-field/SelectInputField';
import { GroupType } from '../../../../../constants/group.constants';
import MdtService from '../../../../../services/api/MdtService';
import ThreadAddMemberModal from './ThreadAddMemberModal';
import Button from '../../../../../components/button/Button';
import { EditorState } from 'draft-js';
import DisclaimerDialog from '../../../../../components/disclaimer-dialog/DisclaimerDialog';
import {
  ThreadPublishDisclaimer,
  ThreadAddTitleNameMap,
  ThreadAttachmentOperation,
} from '../../../../../constants/thread.constants';
import ThreadAttachFile from './components/ThreadAttachFile';
import S3Service from '../../../../../services/s3/S3Service';

const createThreadValidationSchema = yup.object({
  title: yup
    .string()
    .min(5, 'Min title size 5 characters')
    .max(280, 'Max title size 280 characters')
    .required('Title field is required'),
  groupId: yup.string().required('Group field is required'),
  text: yup
    .string()
    .transform((value) => value.getCurrentContent().getPlainText())
    .max(3000, 'Max text size 3000 characters'),
  attachments: yup
    .array()
    .max(25, `No more than 25 attachments are allowed`)
    .of(
      yup
        .mixed()
        .nullable()
        .test('fileSize', 'Attachment size should be less than 3 MB', ({ file }) => {
          return file === null || !file?.size || file?.size <= 3_000_000;
        })
        .test('type', 'Unsupported Format', ({ file }) => {
          return file === null || !file?.size || ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type);
        }),
    ),
});

export default function ThreadAddModal({ open, setOpen, group, type }) {
  const [loading, setLoading] = useState(false);
  const [addMemberModal, setAddMemberModal] = useState(false);
  const [disclaimerDialog, setDisclaimerDialog] = useState(false);

  const [memberIds, setMemberIds] = useState([]);
  const [groups, setGroups] = useState([]);

  const { addThread } = useMdtContext();

  useEffect(() => {
    if (!open) {
      setAddMemberModal(false);
      setDisclaimerDialog(false);
    }
  }, [open]);

  useEffect(() => {
    const fetchGroups = async () => {
      const { body } = await MdtService.getGroups({ type: GroupType.MDT });
      setGroups(body);
    };
    open && !group && fetchGroups().catch((error) => console.error(error));
  }, [open, group]);

  const onClose = () => {
    setOpen(false);
    threadFormik.resetForm();
  };
  const threadFormik = useFormik({
    initialValues: {
      title: '',
      text: useMemo(() => EditorState.createEmpty(), []),
      groupId: group?.id || '',
      memberIds: [],
      attachments: [],
    },
    validationSchema: createThreadValidationSchema,
    enableReinitialize: true,

    onSubmit: async () => {
      setDisclaimerDialog(true);
    },
  });

  const addThreadAttachment = async (threadId, attachment) => {
    if (attachment.operation === ThreadAttachmentOperation.ADD) {
      const { body } = await MdtService.getThreadAttachmentUploadSignature(threadId, { name: attachment.url });
      await S3Service.signedUrlDocumentUpload(body.url, attachment.file);
    }
  };

  const addThreadAttachments = async (threadId, attachments) => {
    if (attachments.length) {
      await Promise.all(attachments.map((attachment) => addThreadAttachment(threadId, attachment)));
    }
  };

  const onAgreeAndPublish = async (values) => {
    try {
      setDisclaimerDialog(false);
      setLoading(true);

      const { body } = await addThread(values.groupId, {
        title: values.title,
        text: convertEditorStateToHtml(values.text),
        memberIds,
      });

      await addThreadAttachments(body.id, values.attachments);

      setMemberIds([]);
      onClose();
      toast.success('Thread was successfully created');
    } catch (error) {
      const message = error?.response?.data?.message || 'Thread creation error';
      toast.error(message);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const isMdtGroup = () => type === GroupType.MDT;

  const groupLabel = isMdtGroup(type) ? 'Organisation' : 'Group';

  const displayGroupField = () => {
    if (group)
      return <TextInputField label={groupLabel} id="groupName" name="groupName" disabled={true} value={group.name} />;

    return (
      <SelectInputField
        label={groupLabel}
        name="groupId"
        value={threadFormik.values.groupId}
        onChange={(e) => {
          setMemberIds([]);
          threadFormik.handleChange(e);
        }}
        onBlur={threadFormik.handleBlur}
        error={threadFormik.touched.groupId && Boolean(threadFormik.errors.groupId)}
        errorHelperText={threadFormik.errors.groupId}
      >
        {groups.map((group) => (
          <MenuItem key={group.id} value={group.id}>
            {group.name}
          </MenuItem>
        ))}
      </SelectInputField>
    );
  };

  const displayAddMember = () => {
    if (!isMdtGroup()) return;

    return (
      <div className={styles.addMemberWrapper}>
        <div>
          <Button
            className={styles.addMemberButton}
            type="none"
            disabled={!group?.id && !threadFormik.values?.groupId}
            rounded
            onClick={(e) => {
              e.preventDefault();
              setAddMemberModal((prevAddMemberModal) => !prevAddMemberModal);
            }}
          >
            Add Members
          </Button>
          <FormHelperText className={styles.addMemberButtonHelper}>
            Manually Added Members: {memberIds.length}
          </FormHelperText>
        </div>
        <ThreadAddMemberModal
          open={addMemberModal}
          setOpen={setAddMemberModal}
          memberIds={memberIds}
          setMemberIds={setMemberIds}
          groupId={group?.id || threadFormik.values.groupId}
        />
      </div>
    );
  };

  return (
    <Modal open={open} onClose={onClose}>
      <form component="form" onSubmit={threadFormik.handleSubmit}>
        <ModalTitle>{ThreadAddTitleNameMap[type]}</ModalTitle>
        {displayGroupField()}
        <TextInputField
          label="Title"
          id="title"
          name="title"
          value={threadFormik.values.title}
          onChange={threadFormik.handleChange}
          onBlur={threadFormik.handleBlur}
          error={threadFormik.touched.title && Boolean(threadFormik.errors.title)}
          errorHelperText={threadFormik.errors.title}
        />

        <WysiwygEditor
          id="text"
          name="text"
          editorState={threadFormik.values.text}
          wrapperClassName={styles.editorWrapper}
          editorClassName={styles.editor}
          toolbarClassName={styles.toolbar}
          onEditorStateChange={(value) => threadFormik.setFieldValue('text', value)}
          error={threadFormik.touched.text && Boolean(threadFormik.errors.text)}
          errorHelperText={threadFormik.errors.text}
        />

        <ThreadAttachFile
          onChange={(value) => threadFormik.setFieldValue('attachments', value)}
          attachments={threadFormik.values.attachments}
          error={threadFormik.touched.attachments && threadFormik.errors.attachments}
        />
        {displayAddMember()}

        <ModalButton loading={loading} type="submit">
          Publish
        </ModalButton>
        <DisclaimerDialog
          open={disclaimerDialog}
          text={ThreadPublishDisclaimer[type]}
          onClose={() => setDisclaimerDialog(false)}
          onConfirm={() => onAgreeAndPublish(threadFormik.values)}
        />
      </form>
    </Modal>
  );
}
