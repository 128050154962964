import React, { useEffect, useState } from 'react';
import styles from './MdtOnboardingPage.module.scss';
import AddressStep from './steps/AddressStep';
import IdentityStep from './steps/IdentityStep';
import CredentialCertificatesStep from './steps/CredentialCertificatesStep';
import RecentAnnualAppraisalStep from './steps/RecentAnnualAppraisalStep';
import CriminalBackgroundCheckStep from './steps/CriminalBackgroundCheckStep';
import MdtOnboardingStepper from './MdtOnboardingStepper';
import ApplicationReviewStep from './steps/ApplicationReviewStep';
import { UserProfileStatus } from '../../../constants/user.constants';
import OrganisationAffiliationStep from './steps/OrganisationAffiliationStep/OrganisationAffiliationStep';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getOrganisations } from '../../../store/slices/organisations/organisationsThunks';
import { OrganisationView } from '../../../types/enums/OrganisationView';
import { getOnboardingProfile } from '../../../store/slices/onboardingProfile/onboardingProfileThunks';
import {
  selectOnboardingProfile,
  syncOnboardingProfile,
} from '../../../store/slices/onboardingProfile/onboardingProfileSlice';
import ErrorMessage from '../../../components/feedback/ErrorMessage/ErrorMessage';
import FullscreenLoader from '../../../components/feedback/FullscreenLoader/FullscreenLoader';
import { OrganisationCategory } from '../../../types/enums/OrganisationCategory';

const steps = [
  'Place of Registration/Medical Practise',
  'Identity',
  'Credential Certificates',
  'Recent Annual Appraisal',
  'Criminal Background Check',
  'Organisation Affiliation',
  'Application Review',
];

export default function MdtOnboardingPage() {
  const dispatch = useAppDispatch();

  const { onboardingProfile, loading, error } = useAppSelector(selectOnboardingProfile);

  const [activeStep, setActiveStep] = useState(0);

  const componentStepMap = {
    0: AddressStep,
    1: IdentityStep,
    2: CredentialCertificatesStep,
    3: RecentAnnualAppraisalStep,
    4: CriminalBackgroundCheckStep,
    6: ApplicationReviewStep,
  };
  const ActiveStepComponent = componentStepMap[activeStep];

  const isStepperDisabled = [
    UserProfileStatus.IN_REVIEW,
    UserProfileStatus.APPROVED,
    UserProfileStatus.REJECTED,
  ].includes(onboardingProfile?.status);

  useEffect(() => {
    dispatch(getOrganisations({ view: OrganisationView.ALL, category: OrganisationCategory.PRIVATE }));
    dispatch(getOnboardingProfile());
  }, []);

  useEffect(() => {
    if (onboardingProfile) updateActiveStep(onboardingProfile);
  }, [onboardingProfile]);

  const handleSyncOnboardingProfile = (profile) => dispatch(syncOnboardingProfile(profile));

  const handleBack = () => setActiveStep((activeStep) => activeStep - 1);
  const handleNext = () => {
    setActiveStep((activeStep) => {
      const nextStep = activeStep + 1;

      // This is done because both update profile effect and handleNext change steps. Should be fixed after each step updates profile correctly
      return nextStep > 6 ? 6 : nextStep;
    });
  };

  const renderStep = (step) => {
    switch (step) {
      case 5:
        return <OrganisationAffiliationStep onBack={handleBack} onNext={handleNext} />;

      default:
        return (
          <ActiveStepComponent
            steps={steps}
            profile={onboardingProfile}
            setProfile={handleSyncOnboardingProfile}
            activeStep={activeStep}
            handleBack={handleBack}
            handleNext={handleNext}
          />
        );
    }
  };

  const updateActiveStep = (profile) => {
    if (
      [
        UserProfileStatus.IN_REVIEW,
        UserProfileStatus.CHANGES_REQUESTED,
        UserProfileStatus.APPROVED,
        UserProfileStatus.REJECTED,
      ].includes(profile.status)
    ) {
      setActiveStep(6);
    } else if (!profile.address?.city || !profile.address.document) {
      setActiveStep(0);
    } else if (!profile.identity.document) {
      setActiveStep(1);
    } else if (!profile.qualityCredential.medicalRegistrationNumber) {
      setActiveStep(2);
    } else if (!profile.annualSalary.latestAppraisalDate) {
      setActiveStep(3);
    } else if (!profile.criminalBackground.document) {
      setActiveStep(4);
    } else if (!profile.onboardingOrganisation?.id) {
      setActiveStep(5);
    } else setActiveStep(6);
  };

  const handleStepChange = (stepIndex) => {
    setActiveStep(stepIndex);
  };

  if (loading)
    return (
      <div className={styles.container}>
        <FullscreenLoader />
      </div>
    );

  if (!onboardingProfile || error)
    return (
      <div className={styles.container}>
        <ErrorMessage>{error || 'Failed to load onboarding profile'}</ErrorMessage>
      </div>
    );

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <h2>M3 Onboarding</h2>
      </div>
      <div className={styles.stepperContainer}>
        <MdtOnboardingStepper
          steps={steps}
          activeStep={activeStep}
          onStepChange={handleStepChange}
          disabled={isStepperDisabled}
        />

        <div className={styles.activeStepComponentContainer}>{renderStep(activeStep)}</div>
      </div>
    </div>
  );
}
