import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import { useFormik } from 'formik';
import { toast } from 'react-toastify';
import FormHelperText from '@mui/material/FormHelperText';

import TextInputField from '../../../../components/text-input-field/TextInputField';

import * as yup from 'yup';

import Button from '../../../../components/button/Button';
import DemoService from '../../../../services/api/DemoService';
import { logAnalyticsEvent } from '../../../../utils/analytics';
import { AnalyticsEvent } from '../../../../constants/analytics.constants';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .min(2, `Invalid First Name field`)
    .max(255, `Invalid First Name field`)
    .required('First Name field is required'),
  lastName: yup
    .string()
    .min(2, `Invalid Last Name field`)
    .max(255, `Invalid Last Name field`)
    .required('Last Name field is required'),
  email: yup.string().max(255, `Invalid Email field`).email(`Invalid Email field`).required('Email field is required'),
  phoneNumber: yup
    .string()
    .min(8, `Invalid Phone Number length`)
    .max(255, `Invalid Phone Number length`)
    .matches(/^\+\d+/, 'Please start your phone number with your country code')
    .required('Phone Number field is required'),
  company: yup
    .string()
    .min(1, `Invalid Company field`)
    .max(255, `Invalid Company field`)
    .required('Company field is required'),
  role: yup.string().min(1, `Invalid Role field`).max(255, `Invalid Role field`).required('Role field is required'),
  hearFrom: yup.string().max(2500, `Invalid field`),
});

export default function SignUpForm() {
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      company: '',
      role: '',
      hearFrom: '',
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      await DemoService.createDemo(values);

      logAnalyticsEvent(AnalyticsEvent.DEMO_REQUEST);

      setSubmitting(false);

      navigate('/');
      toast.success('You successfully scheduled your demo');
    },
  });

  return (
    <form component="form" onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextInputField
            fullWidth
            label="First Name"
            id="firstName"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            errorHelperText={formik.errors.firstName}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <TextInputField
            label="Last Name"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            errorHelperText={formik.errors.lastName}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Phone Number"
            name="phoneNumber"
            value={formik.values.phoneNumber}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.phoneNumber && Boolean(formik.errors.phoneNumber)}
            errorHelperText={formik.errors.phoneNumber}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Email"
            name="email"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            errorHelperText={formik.errors.email}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Company"
            name="company"
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && Boolean(formik.errors.company)}
            errorHelperText={formik.errors.company}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="Role"
            name="role"
            value={formik.values.role}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.role && Boolean(formik.errors.role)}
            errorHelperText={formik.errors.role}
          />
        </Grid>

        <Grid item xs={12}>
          <TextInputField
            label="How did you hear about us?"
            name="hearFrom"
            multiline
            rows={4}
            value={formik.values.hearFrom}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.hearFrom && Boolean(formik.errors.hearFrom)}
            errorHelperText={formik.errors.hearFrom}
          />
        </Grid>
      </Grid>
      <Button sx={{ mt: '24px' }} fullWidth loading={formik.isSubmitting}>
        Schedule demo
      </Button>
      <Grid container justifyContent="flex-end" sx={{ mb: 2 }}>
        <Grid item>
          <FormHelperText>
            By submitting this form, I agree that the{' '}
            <Link href="terms-of-use" target="_blank" underline="none">
              Terms of Use
            </Link>{' '}
            and{' '}
            <Link href="privacy-policy" target="_blank" underline="none">
              Privacy Policy
            </Link>{' '}
            will govern the use of services I receive and personal data I provide respectively.
          </FormHelperText>
        </Grid>
      </Grid>
    </form>
  );
}
