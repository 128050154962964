import React, { useEffect, useRef, useState } from 'react';

import styles from './ThreadMessages.module.scss';
import ThreadMessage from './ThreadMessage';
import dayjs from 'dayjs';

import { Box } from '@mui/material';
import classNames from 'classnames';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import IconButton from '../../../../../components/buttons/IconButton/IconButton';

export default function ThreadMessages({
  thread,
  formik,
  editPeriod,
  editMessageId,
  setEditMessageId,
  permissions,
  messagesEndRef,
}) {
  if (thread === null) {
    return (
      <div className={styles.threadMessagesWrapper}>
        <Box className={styles.timelineScrollbarWrapper}>
          <div className={styles.timelineMessages}>
            {new Array(4).fill().map((_, idx) => (
              <ThreadMessage key={idx} message={null} />
            ))}
          </div>
        </Box>
      </div>
    );
  }

  const contentRef = useRef();
  const overlayRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0);
  const [totalContainerHeight, setTotalContainerHeight] = useState(0);
  const [showScrollButton, setShowScrollButton] = useState(false);

  useEffect(() => {
    const container = contentRef.current;
    const overlay = overlayRef.current;

    setTotalContainerHeight(container?.scrollHeight - container?.clientHeight || 0);

    if (container) {
      const handleScroll = () => {
        if (container && overlay) {
          const { scrollTop, scrollHeight, clientHeight } = container;
          const totalScrollableHeight = scrollHeight - clientHeight;

          setTotalContainerHeight(totalScrollableHeight);
          const currentIndex = Math.floor((scrollTop / totalScrollableHeight) * thread.messages.length);
          setActiveIndex(Math.max(0, Math.min(currentIndex, thread.messages.length - 1)));
          overlay.style.top = `${
            (scrollTop / totalScrollableHeight) * (container.getBoundingClientRect().height - overlay.clientHeight)
          }px`;
        }
      };

      container.addEventListener('scroll', handleScroll);
      return () => container.removeEventListener('scroll', handleScroll);
    }
  }, [thread.messages.length]);

  useEffect(() => {
    const checkScrollPosition = () => {
      const topOffset = window.scrollY + window.innerHeight;
      const viewportHeight = window.innerHeight;
      const totalHeight = document.documentElement.scrollHeight;

      if (window.scrollY > viewportHeight && topOffset < (totalHeight - viewportHeight)) {
        setShowScrollButton(true);
      } else {
        setShowScrollButton(false);
      }
    };

    window.addEventListener('scroll', checkScrollPosition);

    return () => {
      window.removeEventListener('scroll', checkScrollPosition);
    };
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return (
      dayjs(dateString).format('DD MMM YYYY') ||
      `${date.toLocaleDateString('en-GB', { month: 'short' })} ${date.getFullYear()}`
    );
  };

  const displayOverlay = () => {
    if (!thread.messages?.length) return;

    return (
      <div ref={overlayRef} className={classNames(styles.overlay, { [styles.hideOverlay]: totalContainerHeight <= 0 })}>
        <div style={{ fontWeight: 'bold' }}>
          {activeIndex + 1}/{thread.messages.length}
        </div>
        <div>{thread.messages[activeIndex] && formatDate(thread.messages[activeIndex].createdAt)}</div>
      </div>
    );
  };
  const divRef = useRef();


  return (
    <div className={styles.threadMessagesWrapper}>
      <Box className={styles.timelineScrollbarWrapper}>

        {
          showScrollButton &&
          <IconButton className={styles.threadMessagesBottomScroll} icon={<KeyboardArrowDownIcon fontSize='large' />} onClick={() => divRef.current.scrollIntoView({ behavior: "smooth" })}>
          </IconButton>
          }
        <Box
          ref={contentRef}
          className={styles.timelineMessages}
          sx={{
            overflowY: totalContainerHeight > 0 ? 'scroll' : 'unset',
          }}
        >
          {thread.messages.map((message) => (
            <ThreadMessage
              key={message.id}
              message={message}
              permissions={permissions}
              formik={formik}
              editMessageId={editMessageId}
              setEditMessageId={setEditMessageId}
              editPeriod={editPeriod}
            />
          ))}
          <div ref={messagesEndRef} />
        </Box>

        {displayOverlay()}
        <div ref={divRef}></div>
      </Box>
    </div>
  );
}
