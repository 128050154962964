import ApiClient from './ApiClient';
import { ApiRoute } from './ApiRoute';
import { getResponseData } from './adapterUtils';
import { UserDetails } from '../../types/User';
import { OnboardingReviewDTO } from '../../types/DTOs/OnboardingReviewDTO';
import { UserNotificationSettings } from '../../types/UserNotificationSettings';

class UsersService {
  async getUserDetailsById(id: string) {
    const response = await ApiClient.get<UserDetails>(`${ApiRoute.USERS}/${id}`);

    return getResponseData(response);
  }

  async getUserDocument(s3Url: string) {
    const response = await ApiClient.get<{
      url: string;
    }>(`${ApiRoute.ONBOARDING_PROFILE}/document-url`, {
      params: { url: s3Url },
    });

    return getResponseData(response).url;
  }

  async updateUser(id: string, data: { mdtProfile: OnboardingReviewDTO }) {
    const response = await ApiClient.patch<{
      id: string;
    }>(`${ApiRoute.USERS}/${id}`, data);

    return getResponseData(response).id;
  }

  async removeUserFromOrganisation(organisationId: string, userId: string) {
    await ApiClient.delete(`${ApiRoute.GROUPS}/${organisationId}/users/${userId}`);
  }

  async getUserNotificationsSettings() {
    const response = await ApiClient.get<UserNotificationSettings>(`${ApiRoute.USERS_NOTIFICATION_SETTINGS}`);

    return getResponseData(response);
  }

  async updateUserNotificationsSettings(payload: Partial<UserNotificationSettings>) {
    const response = await ApiClient.patch<{ id: string; }>(`${ApiRoute.USERS_NOTIFICATION_SETTINGS}`, payload);

    return getResponseData(response).id;
  }
}

export default new UsersService();
