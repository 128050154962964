import React from 'react';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';

import SignInForm from './SignInForm/SignInForm';
import classes from './SignInPage.module.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { SignInDTO } from '../../../types/DTOs/SignInDTO';
import { confirmMultifactorAuth, signIn } from '../../../store/slices/auth/authThunks';
import { resetMultifactorVerificationData, selectAuth } from '../../../store/slices/auth/authSlice';
import ConfirmMultifactorAuthForm from '../../../components/forms/ConfirmMultifactorAuthForm/ConfirmMultifactorAuthForm';

const SignInPage: React.FC = () => {
  const dispatch = useAppDispatch();

  const { multifactorVerificationData } = useAppSelector(selectAuth);

  const handleSignIn = async (credentials: SignInDTO) => {
    await dispatch(signIn(credentials)).unwrap();
  };

  const handleConfirmMultifactorAuth = async (code: string) => {
    await dispatch(confirmMultifactorAuth(code)).unwrap();
  };

  const handleCancelMultifactorAuth = () => dispatch(resetMultifactorVerificationData());

  return (
    <Container className={classes.container}>
      <Typography className={classes.title} component="h1" variant="h4">
        Sign In
      </Typography>

      {multifactorVerificationData ? (
        <>
          <Typography align="center" className={classes.mfaDescription}>
            Please open your authentication app for the second step of Multi-Factor Authentication. Enter the unique,
            time-sensitive code displayed in the provided field.
          </Typography>

          <ConfirmMultifactorAuthForm onSubmit={handleConfirmMultifactorAuth} onCancel={handleCancelMultifactorAuth} />
        </>
      ) : (
        <SignInForm onSubmit={handleSignIn} />
      )}
    </Container>
  );
};

export default SignInPage;
