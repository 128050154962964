import React from 'react';
import styles from './ModalButton.module.scss';
import classNames from 'classnames';
import Button from '../button/Button';

export default function ModalButton({ loading, children, onClick, className, disabled, type = 'button' }) {
  return (
    <Button
      disabled={disabled}
      className={classNames(styles.modalButton, className)}
      containerClassName={styles.modalButtonContainer}
      type={type}
      loading={loading}
      fullWidth
      onClick={onClick}
      variant="contained"
    >
      {children}
    </Button>
  );
}
