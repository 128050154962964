import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { EditorState } from 'draft-js';
import * as yup from 'yup';
import { useParams } from 'react-router-dom';

import ThreadHeader from '../components/thread/ThreadHeader';
import ThreadDetails from '../components/thread/ThreadDetails';
import ThreadMessages from '../components/thread/ThreadMessages';
import ThreadEditor from '../components/thread/ThreadEditor';
import ThreadLayout from '../components/thread/ThreadLayout/ThreadLayout';
import { useMdtContext } from '../../../../hooks/useMdtContext';
import { convertEditorStateToHtml } from '../../../../utils/editor';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { getUserRole } from '../../../../utils/permissions';
import { MdtPermissions, UserRole } from '../../../../constants/user.constants';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { clearThread, selectThread } from '../../../../store/slices/thread/threadSlice';
import { addThreadMessage, editThreadMessage, getThread } from '../../../../store/slices/thread/threadThunks';
import { usePolling } from '../../../../hooks/usePolling';
import { config } from '../../../../configs/config';

const messageValidationSchema = yup.object({
  text: yup
    .string()
    .transform((value) => value.getCurrentContent().getPlainText())
    .max(3000, 'Max message size 3000 characters'),
});

export default function PublicThread() {
  const params = useParams();
  const dispatch = useAppDispatch();

  const { thread, error: threadError, isLoading } = useAppSelector(selectThread);

  const [editMessageId, setEditMessageId] = useState(null);
  const messagesEndRef = useRef(null);

  const { group } = useMdtContext();
  const { user } = useAuthContext();

  useEffect(() => {
    dispatch(getThread({ id: params.threadId }));

    return () => dispatch(clearThread());
  }, [params.threadId]);

  usePolling({
    callback: () => dispatch(getThread({ id: params.threadId, isPolling: true })),
    interval: config.httpPolling.thread.interval,
    skip: isLoading,
  });

  const canEdit = user.id === thread?.user?.id && !thread.messages?.length;
  const rolePermissions = {
    [UserRole.ADMIN]: [
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.EDIT_THREAD,
      MdtPermissions.DELETE_THREAD,
      MdtPermissions.READ_THREAD,
      MdtPermissions.DELETE_MESSAGE,
    ],
    [UserRole.DEFAULT]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_THREAD,
      canEdit && MdtPermissions.EDIT_THREAD,
      canEdit && MdtPermissions.DELETE_THREAD,
    ],
  };
  const permissions = rolePermissions[getUserRole(user.scopes)] || [];

  const formik = useFormik({
    initialValues: { text: EditorState.createEmpty() },
    validationSchema: messageValidationSchema,
    onSubmit: async (values) => {
      try {
        if (!values.text.getCurrentContent().hasText()) return;

        const markup = convertEditorStateToHtml(values.text);
        if (editMessageId) {
          await dispatch(
            editThreadMessage({
              threadId: thread.id,
              messageId: editMessageId,
              text: markup,
            }),
          ).unwrap();

          setEditMessageId(null);
        } else {
          await dispatch(
            addThreadMessage({
              threadId: thread.id,
              text: markup,
            }),
          ).unwrap();

          messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        }

        formik.setFieldValue('text', EditorState.createEmpty());
      } catch {}
    },
  });

  return (
    <ThreadLayout
      header={<ThreadHeader group={group} thread={thread} permissions={permissions} isLoading={isLoading} />}
    >
      {threadError ? (
        <ErrorMessage>{threadError}</ErrorMessage>
      ) : (
        <>
          <ThreadDetails thread={thread} />

          <ThreadMessages
            editPeriod={3600}
            permissions={permissions}
            thread={thread}
            formik={formik}
            editMessageId={editMessageId}
            setEditMessageId={setEditMessageId}
            messagesEndRef={messagesEndRef}
          />

          <ThreadEditor
            isEdit={!!editMessageId}
            formik={formik}
            error={formik.touched.text && Boolean(formik.errors.text)}
            errorHelperText={formik.errors.text}
          />
        </>
      )}
    </ThreadLayout>
  );
}
