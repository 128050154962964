import React, { useState, useEffect } from 'react';

import styles from './AdminUserPage.module.scss';
import UserService from '../../../services/api/UserService';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Typography,
  Avatar,
  TextField,
  ButtonGroup,
  ListItemText,
  List,
  ListItemButton,
  IconButton,
} from '@mui/material';
import { UserProfileStatus, UserProfileStatusMap, UserStatus } from '../../../constants/user.constants';
import dayjs from 'dayjs';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import { useFormik } from 'formik';
import Chip from '../../../components/chips/Chip/Chip';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

import Button from '../../../components/button/Button';
import UserProfileWrapper from '../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../components/user-profile/UserProfileItem';

// TODO: Refactor component
export default function AdminUserPage() {
  const [user, setUser] = useState({});
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();

  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const params = useParams();
  const mdtApplicationStatus = UserProfileStatusMap[user?.mdtProfile?.status];

  useEffect(() => {
    const fetchInitialData = async () => {
      const { body } = await UserService.getUser(params.userId);
      setUser(body);
    };
    params.userId && fetchInitialData();
  }, []);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  const handleMenuItemClick = (_, index) => {
    setSelectedIndex(index);
    setOpen(false);
  };

  const onOnboardingDocumentClick = async (url) => {
    const { body } = await UserService.getOnboardingDocumentUrl({ url });

    window.open(body.url);
  };

  const options = [
    { name: UserProfileStatus.CHANGES_REQUESTED, title: 'Request Changes' },
    { name: UserProfileStatus.APPROVED, title: 'Approve' },
    { name: UserProfileStatus.REJECTED, title: 'Reject' },
  ];

  const formik = useFormik({
    initialValues: { onboardingReviewComment: '', status: '' },
    onSubmit: async (values, { resetForm }) => {
      const mdtProfile = {
        onboardingReviewComment: values.onboardingReviewComment || undefined,
        status: options[selectedIndex].name,
      };

      await UserService.updateProfile(user.id, { mdtProfile });

      setUser({
        ...user,
        status: mdtProfile?.status === UserProfileStatus.APPROVED ? UserStatus.ACTIVE : undefined,
        mdtProfile: { ...user.mdtProfile, ...mdtProfile },
      });

      resetForm();
    },
  });

  const handleStatusUpdate = async (status) => {
    await UserService.updateProfile(user.id, { status });

    setUser({ ...user, status });
  };

  const displayMdtProfileActions = () => {
    if (user?.mdtProfile?.status !== UserProfileStatus.IN_REVIEW) {
      return;
    }

    return (
      <form onSubmit={formik.handleSubmit}>
        <Typography className={styles.cardItem} variant="body2">
          <TextField
            className={styles.reviewComment}
            id="outlined-multiline-static"
            name="onboardingReviewComment"
            value={formik.values.onboardingReviewComment}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            fullWidth
            multiline
            rows={2}
            placeholder="Onboarding Review Comment (optional)"
          />
        </Typography>
        <ButtonGroup className={styles.buttonGroup} variant="contained" aria-label="split button">
          <Button
            loading={formik.isSubmitting}
            variant="contained"
            type="submit"
            className={styles.actionButton}
            refAnchor={anchorRef}
          >
            {options[selectedIndex].title}
          </Button>
          <Button
            className={styles.optionsButton}
            type="none"
            disabled={formik.isSubmitting}
            onClick={(e) => {
              e.preventDefault();
              setOpen((prevOpen) => !prevOpen);
            }}
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>

        <Popper
          sx={{ zIndex: 1, width: 'max-content' }}
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
                width: anchorRef.current ? anchorRef.current.offsetWidth : null,
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList id="split-button-menu" autoFocusItem>
                    {options.map((option, index) => (
                      <MenuItem
                        key={option.name}
                        selected={index === selectedIndex}
                        onClick={(event) => handleMenuItemClick(event, index)}
                      >
                        {option.title}
                      </MenuItem>
                    ))}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </form>
    );
  };

  const displayUploadedFiles = () => {
    const files = [
      user.mdtProfile?.addressDocument,
      user.mdtProfile?.identityDocument,
      user.mdtProfile?.criminalBackgroundDocument,
      user.mdtProfile?.qualityCredentialDocument,
    ]
      .filter((file) => file)
      .map((url) => {
        const [type, name] = url.split('/').slice(-2);

        return { type, name, url };
      });
    const typeMap = {
      address: 'Address',
      identity: 'Identity',
      criminalBackground: 'Criminals Background',
      qualityCredential: 'Quality',
    };

    if (!files.length) {
      return (
        <List>
          <ListItemButton
            disabled={true}
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            className={styles.documentButton}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <ListItemText primary={'There are no uploaded documents yet'} />
            </Typography>
          </ListItemButton>
        </List>
      );
    }

    return (
      <List>
        {files.map((file, index) => (
          <ListItemButton
            key={index}
            rel="noopener noreferrer"
            variant="outlined"
            color="primary"
            className={styles.documentButton}
            onClick={() => onOnboardingDocumentClick(file.url)}
          >
            <Typography sx={{ display: 'flex', alignItems: 'center', gap: '12px' }}>
              <FileDownloadIcon fontSize="large" />
              <ListItemText primary={file.name} />
            </Typography>
            <Chip color="default" size="small" label={typeMap[file.type]} />
          </ListItemButton>
        ))}
      </List>
    );
  };

  const displayActionButtons = () => (
    <>
      {user.status === UserStatus.ACTIVE && (
        <Button type="" className={styles.disableButton} onClick={() => handleStatusUpdate(UserStatus.INACTIVE)}>
          Deactivate
        </Button>
      )}
      {user.status === UserStatus.INACTIVE && user?.mdtProfile?.status !== UserProfileStatus.IN_REVIEW && (
        <Button type="" className={styles.enableButton} onClick={() => handleStatusUpdate(UserStatus.ACTIVE)}>
          Activate
        </Button>
      )}
    </>
  );

  return (
    <div className={styles.wrapper}>
      <UserProfileWrapper
        title={
          <div className={styles.header}>
            <IconButton onClick={() => navigate(`/internal/admin/users`)}>
              <ChevronLeftIcon />
            </IconButton>{' '}
            User Profile
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <UserProfileItem title="Name">
            <div className={styles.profileWrapper}>
              <div className={styles.profile}>
                <Avatar>
                  {user?.firstName && user?.firstName[0]}
                  {user?.lastName && user?.lastName[0]}
                </Avatar>
                <Typography>
                  {' '}
                  {user.firstName} {user.lastName}
                </Typography>
              </div>

              {displayActionButtons()}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Email address">
            <div className={styles.item}>{user.email}</div>
          </UserProfileItem>
          <UserProfileItem title="Phone number">
            <div className={styles.item}>{user.phoneNumber}</div>
          </UserProfileItem>

          <UserProfileItem title="Place of Registration/Medical Practise">
            <div className={styles.item}>
              {user.address
                ? [
                    user?.address?.addressLine2,
                    user?.address?.addressLine1,
                    user?.address?.city,
                    user?.address?.zipCode,
                    user?.address?.country,
                  ]
                    .filter((item) => item)
                    .join(', ')
                : ''}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Speciality">
            <div className={styles.item}>{<Chip size="small" color="primary" label={user.speciality || 'N/A'} />}</div>
          </UserProfileItem>

          <UserProfileItem title="Medical registration number">
            <div className={styles.item}>{user?.mdtProfile?.medicalRegistrationNumber}</div>
          </UserProfileItem>

          <UserProfileItem title="Latest appraisal date">
            <div className={styles.item}>
              {user?.mdtProfile?.latestAppraisalDate
                ? dayjs(user.mdtProfile?.latestAppraisalDate).format('MMM DD YYYY')
                : 'N/A'}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Created at">
            <div className={styles.item}>{dayjs(user?.createdAt).format('MMM DD YYYY H:mm A') || 'N/A'}</div>
          </UserProfileItem>

          <UserProfileItem title="Application status">
            <div className={styles.item}>
              {mdtApplicationStatus && (
                <Chip
                  size="small"
                  label={mdtApplicationStatus.label}
                  icon={mdtApplicationStatus.icon}
                  color={mdtApplicationStatus.color}
                />
              )}
            </div>
          </UserProfileItem>

          <UserProfileItem title="Latest onboarding review comment">
            <div className={styles.item}>{user.mdtProfile?.onboardingReviewComment || 'N/A'}</div>
          </UserProfileItem>

          <UserProfileItem title="Uploaded documents" className={styles.documentWrapper}>
            <div className={styles.itemDocument}>{displayUploadedFiles()}</div>
          </UserProfileItem>

          {displayMdtProfileActions()}
        </div>
      </UserProfileWrapper>
    </div>
  );
}
