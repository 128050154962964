import React, { useState, useEffect } from 'react';

import styles from './UserPage.module.scss';
import UserService from '../../../services/api/UserService';
import { Navigate, useNavigate, useParams } from 'react-router-dom';
import { Box, Typography, Avatar, IconButton } from '@mui/material';
import Chip from '../../../components/chips/Chip/Chip';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useLoader } from '../../../hooks/useLoader';
import { useAuthContext } from '../../../hooks/useAuthContext';
import UserProfileWrapper from '../../../components/user-profile/UserProfileWrapper';
import UserProfileItem from '../../../components/user-profile/UserProfileItem';

export default function UserPage() {
  const [user, setUser] = useState({});
  const navigate = useNavigate();
  const loader = useLoader();
  const auth = useAuthContext();

  const params = useParams();

  useEffect(() => {
    const fetchInitialData = async () => {
      loader.startLoading();
      const { body } = await UserService.getUser(params.userId);
      setUser(body);
      loader.stopLoading();
    };
    params.userId && fetchInitialData();
  }, []);

  if (auth.user.id === user.id) {
    return <Navigate to="/internal/profile" />;
  }

  return (
    <Box className={styles.wrapper}>
      <UserProfileWrapper
        title={
          <div className={styles.header}>
            <IconButton onClick={() => navigate(`/internal/users`)}>
              <ChevronLeftIcon />
            </IconButton>
            User Profile
          </div>
        }
      >
        <div className={styles.contentWrapper}>
          <UserProfileItem title="Name">
            <div className={styles.profile}>
              <Avatar>
                {user?.firstName && user?.firstName[0]}
                {user?.lastName && user?.lastName[0]}
              </Avatar>
              <Typography>
                {user.firstName} {user.lastName}
              </Typography>
            </div>
          </UserProfileItem>

          <UserProfileItem title="Email address">
            <div className={styles.item}>{user.email}</div>
          </UserProfileItem>

          <UserProfileItem title="Place of Registration/Medical Practise">
            <div className={styles.item}>{user?.address?.country}</div>
          </UserProfileItem>

          <UserProfileItem title="Speciality">
            <div className={styles.item}>{<Chip size="small" color="primary" label={user.speciality || 'N/A'} />}</div>
          </UserProfileItem>

          {
            <UserProfileItem title="Medical registration number">
              <div className={styles.item}>{user?.mdtProfile?.medicalRegistrationNumber}</div>
            </UserProfileItem>
          }
        </div>
      </UserProfileWrapper>
    </Box>
  );
}
