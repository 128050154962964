import Box from '@mui/material/Box';
import { Outlet, useLocation } from 'react-router-dom';
import classNames from 'classnames';

import styles from './ProtectedGroupsLayout.module.scss';
import { useAuthContext } from '../../hooks/useAuthContext';
import ProfilePage from '../../pages/private/profile/ProfilePage';
import GroupMobileNav from '../../components/groups-menu/GroupMobileNav/GroupMobileNav';

const checkIsGroupPath = (pathname) => pathname.match(/^\/[^/]+\/[^/]+\/[^/]+$/);

export default function ProtectedGroupsLayout() {
  const auth = useAuthContext();
  const { pathname } = useLocation();

  const isGroupPath = checkIsGroupPath(pathname);

  if (!auth.isBasic()) return <ProfilePage />;

  return (
    <Box component="main" className={classNames(styles.wrapper, isGroupPath && styles.groupPageWrapper)}>
      {isGroupPath && <GroupMobileNav path={pathname} />}

      <Outlet />
    </Box>
  );
}
