import { PayloadAction, SerializedError } from '@reduxjs/toolkit';

import { Feedback } from '../../../types/Feedback';
import { FeedbackStatus } from '../../../types/enums/FeedbackStatus';
import { getErrorMessage } from '../../../utils/error';

export const rejectedActionToFeedback = (
  action: PayloadAction<unknown, string, unknown, SerializedError>,
  defaultMessage: string | undefined = 'Unknown error',
): Feedback => {
  return {
    status: FeedbackStatus.ERROR,
    message: getErrorMessage(action.error, defaultMessage),
  };
};

export const fulfilledActionToFeedback = (message: string | undefined = 'Success'): Feedback => {
  return {
    status: FeedbackStatus.SUCCESS,
    message,
  };
};
