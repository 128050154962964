import React, { useState } from 'react';

import dayjs from 'dayjs';
import styles from './ThreadAttachedFile.module.scss';
import { toast } from 'react-toastify';

import FilePresentRoundedIcon from '@mui/icons-material/FilePresentRounded';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';
import CircularProgress from '@mui/material/CircularProgress';
import MdtService from '../../../../../../services/api/MdtService';
import { downloadS3File } from '../../../../../../utils/file';

export default function ThreadAttachedFile({ threadId, attachment }) {
  const [attachmentDownloading, setAttachmentDownloading] = useState(false);

  const onClick = async () => {
    try {
      setAttachmentDownloading(true);

      const { body } = await MdtService.getThreadAttachmentSignature(threadId, { url: attachment.url });

      await downloadS3File(body.url, attachment.url.split('/').at(-1));
    } catch (error) {
      console.error(error);
      toast.error('Failed to download thread attachment');
    } finally {
      setAttachmentDownloading(false);
    }
  };

  return (
    <div className={styles.attachedFile} onClick={onClick}>
      {attachmentDownloading ? (
        <CircularProgress size={32} />
      ) : (
        [
          <FilePresentRoundedIcon key={1} className={styles.attachedFileIcon} />,
          <FileDownloadRoundedIcon key={2} className={styles.attachedDownloadIcon} />,
        ]
      )}
      <div>
        <div>{attachment.url.split('/').at(-1)}</div>
        <div className={styles.attachedFileDate}>{dayjs(attachment.createdAt).format('MMM DD YYYY H:mm A')}</div>
      </div>
    </div>
  );
}
