export enum ApiRoute {
  PROFILE = 'users/me',
  USERS = 'users',
  USERS_NOTIFICATION_SETTINGS = 'users/notification-settings',
  ONBOARDING_PROFILE = 'users/onboarding',
  ORGANISATIONS = 'mdt/organisations',
  GROUPS = 'mdt/groups',
  THREADS = 'mdt/threads',
  MESSAGES = 'mdt/messages',
  MFA = 'auth/mfa',
  MFA_SETTINGS = 'auth/mfa/settings',
  MFA_ASSOCIATE = 'auth/mfa/associate',
  MFA_DISASSOCIATE = 'auth/mfa/disassociate',
}
