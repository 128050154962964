import React from 'react';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import styles from './ConfirmDialog.module.scss';
import Button from '../button/Button';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  text = 'Are you sure you want to delete this?',
  confirmName = 'Remove',
  fullWidth,
  maxWidth,
}) {
  const handleClose = () => {
    onClose();
  };

  const handleConfirm = async () => {
    await onConfirm();
  };

  return (
    <div className={styles.dialogContainer}>
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        TransitionComponent={Transition}
        PaperProps={{ style: { borderRadius: 15 } }}
        scroll="paper"
        open={open}
        onClose={onClose}
      >
        <DialogTitle id="alert-dialog-title">Please confirm the action</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{text}</DialogContentText>
        </DialogContent>
        <DialogActions className={styles.actionButtons}>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirm} className={styles.confirm}>
            {confirmName}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
