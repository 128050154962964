import React, { useRef } from 'react';

interface Props {
  className?: string;
}

const LogoIcon: React.FC<Props> = ({ className }) => {
  // This is needed because two similar ids break linear gradients in SVGs
  const uniqueId = useRef(Math.random().toString(36).substring(7)).current;

  return (
    <svg className={className} id="Layer_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2083.65 2002.02">
      <defs>
        <clipPath id={`clippath-${uniqueId}`}>
          <path
            d="M2077.06,1184.01c-7.47,0-528.43-2.2-533.71,1.32-4.84,3.52-5.72,13.63-7.47,21.11-33.86,122.24-67.27,244.47-101.13,366.71-.44,2.2-1.32,4.4-3.52,7.04-64.64-277.45-129.71-554.9-194.35-832.79h-1.76c-63.32,241.4-126.63,482.35-189.95,723.75h-1.76c-72.99-221.61-145.98-442.78-218.53-664.39h-1.32c-42.21,237.88-83.98,475.76-126.19,713.63-.44,0-1.32,0-1.76.44-1.32-3.08-2.64-6.16-3.96-9.23-42.65-106.41-85.3-212.82-127.51-319.66-2.64-6.6-6.16-8.35-12.75-8.35H.44v-77.83l-.44-.44h0s604.15,0,604.15,0c8.35,0,11.43,2.64,14.51,10.11,14.95,38.69,30.34,77.39,45.73,115.64.44,1.76,1.32,3.08,2.64,6.6,44.41-251.95,88.38-502.14,132.79-751.89h1.76c77.83,235.68,155.21,471.8,233.04,707.48h1.76c67.71-257.66,135.43-515.33,203.14-773.43h1.76c65.52,280.09,131.03,560.18,196.55,840.71h1.32c.88-2.2,1.76-4.84,2.2-7.47,13.19-47.05,25.94-94.1,38.69-141.14,1.32-5.72,3.96-7.04,9.23-7.04h594.39"
            style={{ fill: 'none', strokeWidth: '0px' }}
          />
        </clipPath>
        <linearGradient
          id={`linear-gradient-${uniqueId}`}
          x1="27.54"
          y1="1001.01"
          x2="2028.03"
          y2="1001.01"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stop-color="#3052a4" />
          <stop offset="1" stop-color="#46aaeb" />
        </linearGradient>
      </defs>
      <g style={{ clipPath: `url(#clippath-${uniqueId})` }}>
        <rect
          x="27.54"
          width="2000.49"
          height="2002.02"
          style={{ fill: `url(#linear-gradient-${uniqueId})`, strokeWidth: '0px' }}
        />
      </g>
    </svg>
  );
};

export default LogoIcon;
