import { Profile } from '../types/Profile';
import { UserScope } from '../types/enums/UserScope';
import { UserStatus } from '../types/enums/UserStatus';

export const getIsAdmin = (profile: Profile | null) => {
  if (!profile) return false;

  return profile.scopes?.includes(UserScope.ADMIN);
};

export const getIsOrganisationAdmin = (profile: Profile | null) => !!profile?.managedOrganisationIds?.length;

export const getIsOnboarding = (profile: Profile | null) => {
  if (!profile) return false;

  return !profile.scopes?.length && [UserStatus.INACTIVE, UserStatus.PENDING].includes(profile.status);
};
