import React from 'react';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { NavLink } from 'react-router-dom';

import styles from './Home.module.scss';

export default function Home({ id }) {
  return (
    <section id={id} className={styles.section}>
      <div className={styles.info}>
        <Typography className={styles.infoTitle}>
          <div>
            The <span className={styles.infoTitlePrescribing}>Secure</span> Collaboration Platform for{' '}
            <span className={styles.infoTitleToday}>Medical</span> Professionals
          </div>
        </Typography>

        <Typography className={styles.description}>
          MedSym enables healthcare professionals worldwide to collaborate online in a secure environment.
          Whether you’re discussing the latest treatments and drugs or collaborating on private Multi-Disciplinary Team (MDT) cases in an approved group with audit trails,
          the MedSym platform improves patient outcomes and professionalises how you communicate with your peers.
        </Typography>

        <Button variant="outlined" to="/sign-up" size="large" className={styles.joinButton} component={NavLink}>
          Register For Your 3 Month Free Trial
        </Button>
      </div>

      <div className={styles.imageContainer}>
        <div className={styles.image} />
      </div>
    </section>
  );
}
