import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import AuthService from '../../../services/api/AuthService';
import { logAnalyticsEvent } from '../../../utils/analytics';
import { AnalyticsEvent } from '../../../constants/analytics.constants';
import UserService from '../../../services/api/UserService';
import { getProfile } from '../profile/profileThunks';
import { SignInDTO } from '../../../types/DTOs/SignInDTO';
import { RootState } from '../../store';
import { SingUpDTO } from '../../../types/DTOs/SignUpDTO';

export const signIn = createAsyncThunk(`${SliceName.AUTH}/signIn`, async (credentials: SignInDTO, { dispatch }) => {
  const data = await AuthService.signIn(credentials);

  if ('session' in data) {
    return {
      multifactorVerificationData: {
        session: data.session,
        email: credentials.email,
      },
    };
  } else {
    logAnalyticsEvent(AnalyticsEvent.SIGN_IN);

    AuthService.setLocalTokens(data);

    await dispatch(getProfile()).unwrap();
  }
});

export const signOut = createAsyncThunk(`${SliceName.AUTH}/signOut`, async () => {
  const token = AuthService.getLocalAccessToken();

  await AuthService.signOut(token);

  // TODO: remove this after user is removed from local storage
  UserService.removeLocalUser();
  AuthService.removeLocalTokens();
});

export const confirmMultifactorAuth = createAsyncThunk<
  void,
  string,
  {
    state: RootState;
  }
>(`${SliceName.AUTH}/confirmMultifactorAuth`, async (code: string, { getState, dispatch }) => {
  const { multifactorVerificationData } = getState().auth;

  if (!multifactorVerificationData) {
    throw new Error('Multi-factor verification data is missing');
  }

  const data = await AuthService.confirmMultifactorAuth({
    ...multifactorVerificationData,
    code,
  });

  logAnalyticsEvent(AnalyticsEvent.SIGN_IN);

  AuthService.setLocalTokens(data);

  await dispatch(getProfile()).unwrap();
});

export const signUp = createAsyncThunk(`${SliceName.AUTH}/signUp`, async (signUpDTO: SingUpDTO) => {
  await AuthService.signUp(signUpDTO);

  logAnalyticsEvent(AnalyticsEvent.SIGN_UP);
});
