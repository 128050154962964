import React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useFormik } from 'formik';
import { useAuthContext } from '../../../../hooks/useAuthContext';

import FormHelperText from '@mui/material/FormHelperText';
import MdtOnboardingStepWrapper from './components/MdtOnboardingStepWrapper';
import UserService from '../../../../services/api/UserService';
import { UserProfileStatus, UserProfileStatusMap } from '../../../../constants/user.constants';
import styles from './ApplicationReviewStep.module.scss';

import Chip from '../../../../components/chips/Chip/Chip';
import { logAnalyticsEvent } from '../../../../utils/analytics';
import { AnalyticsEvent } from '../../../../constants/analytics.constants';
import { useValidateOnboardingProfile } from './hooks/useValidateOnboardingProfile';

export default function ApplicationReviewStep({ profile, setProfile, handleBack }) {
  const { user } = useAuthContext();
  const validateProfile = useValidateOnboardingProfile();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {},
    onSubmit: async (_, { setSubmitting }) => {
      if (profile?.status == UserProfileStatus.IN_REVIEW) return;

      const isValid = validateProfile(profile);

      if (!isValid) return;

      const mdtProfile = { status: UserProfileStatus.IN_REVIEW };
      await UserService.updateProfile(user.id, { mdtProfile });
      setProfile({ ...profile, status: UserProfileStatus.IN_REVIEW });

      logAnalyticsEvent(AnalyticsEvent.ONBOARDING_COMPLETE);

      setSubmitting(false);
    },
  });

  const getOnboardingReviewStatus = () => {
    const active = UserProfileStatusMap[profile.status];
    return (
      <>
        <Grid item xs={4} sm={4} className={styles.gridItem}>
          <Typography className={styles.reviewLabel}>Application Status</Typography>
        </Grid>

        <Grid item xs={8} sm={8} className={styles.gridItem}>
          <Typography className={styles.onboardingReviewStatus} sx={{ backgroundColor: active.backgroundColor }}>
            <Chip label={active.label} icon={active.icon} color={active.color} />
          </Typography>
        </Grid>
      </>
    );
  };

  const getOnboardingReviewFeedback = () => {
    if (
      ![UserProfileStatus.REJECTED, UserProfileStatus.APPROVED, UserProfileStatus.CHANGES_REQUESTED].includes(
        profile.status,
      )
    ) {
      return;
    }

    if (!profile.onboardingReviewComment) {
      return;
    }

    return (
      <>
        <Grid item xs={4} sm={4} className={styles.gridItem}>
          <Typography className={styles.reviewLabel}>Onboarding Reviewer Feedback</Typography>
        </Grid>

        <Grid item className={styles.onboardingReviewFeedback} xs={8} sm={8}>
          <Typography className={styles.reviewValue}>{profile.onboardingReviewComment}</Typography>
        </Grid>
      </>
    );
  };

  return (
    <MdtOnboardingStepWrapper
      name="Application Review"
      formik={formik}
      onSubmit={formik.handleSubmit}
      nextLabel="Submit"
      hideButtons={[
        UserProfileStatus.IN_REVIEW,
        UserProfileStatus.APPROVED,
        UserProfileStatus.REJECTED,
        UserProfileStatus.BLOCKED,
      ].includes(profile?.status)}
      handleBack={handleBack}
    >
      <Grid item xs={4} sm={4} className={styles.gridItem}>
        <Typography className={styles.reviewLabel}>Full Name</Typography>
      </Grid>

      <Grid item xs={8} sm={8} className={styles.gridItem}>
        <Typography className={styles.reviewValue}>
          {profile?.identity.firstName} {profile?.identity.lastName}
        </Typography>
      </Grid>

      <Grid item xs={4} sm={4} className={styles.gridItem}>
        <Typography className={styles.reviewLabel}>Place of Registration / Medical Practise</Typography>
      </Grid>

      <Grid item xs={8} sm={8} className={styles.gridItem}>
        <Typography className={styles.reviewValue}>
          {[
            profile?.address?.addressLine2,
            profile?.address?.addressLine1,
            profile?.address?.city,
            profile?.address?.zipCode,
            profile?.address?.country,
          ]
            .filter((item) => item)
            .join(', ')}
        </Typography>
      </Grid>

      <Grid item xs={4} sm={4} className={styles.gridItem}>
        <Typography className={styles.reviewLabel}>Medical Registration Number</Typography>
      </Grid>

      <Grid item xs={8} sm={8} className={styles.gridItem}>
        <Typography className={styles.reviewValue}>{profile?.qualityCredential?.medicalRegistrationNumber}</Typography>
      </Grid>

      {profile?.onboardingOrganisation && (
        <>
          <Grid item xs={4} sm={4} className={styles.gridItem}>
            <Typography className={styles.reviewLabel}>Organisation</Typography>
          </Grid>

          <Grid item xs={8} sm={8} className={styles.gridItem}>
            <Typography className={styles.reviewValue}>{profile?.onboardingOrganisation?.name}</Typography>
          </Grid>
        </>
      )}

      <Grid item xs={4} sm={4} className={styles.gridItem}>
        <Typography className={styles.reviewLabel}>Latest Annual Appraisal Date</Typography>
      </Grid>

      <Grid item xs={8} sm={8} className={styles.gridItem}>
        <Typography className={styles.reviewValue}>
          {profile?.annualSalary?.latestAppraisalDate &&
            new Date(profile?.annualSalary.latestAppraisalDate).toLocaleDateString('en-US')}
        </Typography>
      </Grid>

      {getOnboardingReviewStatus()}
      {getOnboardingReviewFeedback()}
      <Grid item xs={12} sm={12}>
        <FormHelperText sx={{ textAlign: 'center' }}>
          It can take up to several days to review your onboarding application. You will receive an email once your
          submitted application has been reviewed.
        </FormHelperText>
      </Grid>
    </MdtOnboardingStepWrapper>
  );
}
