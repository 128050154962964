import * as React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';

import styles from './MdtOnboardingStepper.module.scss';
import { StepButton } from '@mui/material';

export default function MdtOnboardingStepper({ steps, onStepChange, activeStep, disabled }) {
  const stepList = steps.map((label, index) => {
    const stepProps = {};
    const labelProps = {};

    return (
      <Step className={styles.stepContainer} key={label} {...stepProps}>
        <StepButton onClick={() => onStepChange(index)} disabled={disabled}>
          <StepLabel classes={{ labelContainer: styles.labelContainer }} {...labelProps}>
            {label}
          </StepLabel>
        </StepButton>
      </Step>
    );
  });

  return (
    <Stepper nonLinear activeStep={activeStep}>
      {stepList}
    </Stepper>
  );
}
