import { createAsyncThunk } from '@reduxjs/toolkit';

import { SliceName } from '../../config';
import { UserDetails } from '../../../types/User';
import { RootState } from '../../store';
import UsersService from '../../../services/api/UsersService';
import { OnboardingReviewDTO } from '../../../types/DTOs/OnboardingReviewDTO';
import OrganisationsService from '../../../services/api/OrganisationsService';
import { downloadS3File } from '../../../utils/file';
import { UpdateProfileDTO } from '../../../types/DTOs/UpdateProfileDTO';
import ProfileService from '../../../services/api/ProfileService';

export const getUserDetails = createAsyncThunk<
  UserDetails,
  void,
  {
    state: RootState;
  }
>(`${SliceName.USER_DETAILS}/getUserDetails`, async (_, { getState }) => {
  const id = getState().userDetails.userId;

  if (!id) throw new Error('User ID is not set');

  return await UsersService.getUserDetailsById(id);
});

export const getUserDocument = createAsyncThunk<
  void,
  {
    organisationId: string;
    s3Url: string;
  },
  {
    state: RootState;
  }
>(
  `${SliceName.USER_DETAILS}/getUserDocument`,
  async ({ s3Url, organisationId }: { s3Url: string; organisationId: string }, { getState }) => {
    const userId = getState().userDetails.userId;

    if (!userId) throw new Error('User ID is not set');

    const url = await OrganisationsService.getUserDocument({
      s3Url,
      organisationId,
      userId,
    });

    await downloadS3File(url, s3Url.split('/').at(-1) || 'document.pdf');
  },
);

export const reviewUserOnboarding = createAsyncThunk<
  {
    id: string;
    review: OnboardingReviewDTO;
  },
  {
    organisationId: string;
    review: OnboardingReviewDTO;
  },
  {
    state: RootState;
  }
>(
  `${SliceName.USER_DETAILS}/reviewUserOnboarding`,

  async ({ review, organisationId }, { getState }) => {
    const id = getState().userDetails.userId;

    if (!id) throw new Error('User ID is not set');

    await OrganisationsService.updateOrganisationUser({ userId: id, organisationId, data: { mdtProfile: review } });

    return {
      id,
      review,
    };
  },
);

export const removeUserFromOrganisation = createAsyncThunk(
  `${SliceName.USER_DETAILS}/removeUserFromOrganisation`,
  async ({ organisationId, userId }: { organisationId: string; userId: string }) => {
    await UsersService.removeUserFromOrganisation(organisationId, userId);
  },
);

export const updateUserDetails = createAsyncThunk<void, UpdateProfileDTO, { state: RootState; }>(
  `${SliceName.ONBOARDING_PROFILE}/updateUserDetails`,
  async (updateProfileDTO, { getState }) => {
    const userDetails = getState().userDetails

    if (!userDetails?.userId) throw new Error('User ID is not set');

    await ProfileService.updateProfile({ id: userDetails.userId, updateProfileDTO });
  }
);
