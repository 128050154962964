import React, { useCallback, useState } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';

import { config } from '../../../../configs/config';
import ToggleButtonField from '../../../../components/toggle-button-field/ToggleButtonField';
import UserService from '../../../../services/api/UserService';
import GroupEmptyState from '../components/GroupEmptyState';
import ThreadsPagination from '../components/ThreadsPagination';
import ThreadsHeader from '../components/ThreadsHeader';
import { getUserRole, hasPermission } from '../../../../utils/permissions';
import { useAuthContext } from '../../../../hooks/useAuthContext';
import { MdtPermissions, UserGroupRole, UserRole } from '../../../../constants/user.constants';
import GroupEditMenuItem from '../components/menus/GroupEditMenuItem';
import GroupEditMenu from '../components/menus/GroupEditMenu';
import GroupEditModal from '../components/modals/GroupEditModal';
import ThreadAddModal from '../components/modals/ThreadAddModal';
import MdtThreads from './MdtThreads';
import GroupManageMembersModal from '../components/modals/GroupManageMembersModal';
import GroupAddMemberModal from '../components/modals/GroupAddMemberModal/GroupAddMemberModal';
import { useMdtContext } from '../../../../hooks/useMdtContext';
import GroupsMenu from '../../../../components/groups-menu/GroupsMenu';

import styles from './MdtGroups.module.scss';
import MdtThreadStatusFilter from './MdtThreadStatusFilter';
import { debounce } from '../../../../utils/debounce';
import Search from '../../../../components/search/Search';
import ConfirmDialog from '../../../../components/confirm-dialog/ConfirmDialog';
import { GroupType } from '../../../../constants/group.constants';
import ErrorMessage from '../../../../components/feedback/ErrorMessage/ErrorMessage';

export default function MdtGroups() {
  const {
    groups,
    startGroupsPolling,
    group,
    groupThreads,
    startGroupPolling,
    groupThreadsCount,
    removeUserFromGroup,
    groupUser,
    groupError,
  } = useMdtContext();
  const { user } = useAuthContext();
  const type = 'MDT';
  const title = 'MDT Organisations';
  const params = useParams();

  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const [openGroupMenu, setOpenGroupMenu] = useState(null);
  const [openEditGroupModal, setOpenEditGroupModal] = useState(false);
  const [openMembersGroupModal, setOpenMembersGroupModal] = useState(false);
  const [openAddMembersGroupModal, setOpenAddMembersGroupModal] = useState(false);
  const [openLeaveConfirmModal, setOpenLeaveConfirmModal] = useState(false);

  const [openAddThreadModal, setOpenAddThreadModal] = useState(false);
  const [search, setSearch] = useState(query.get('title'));

  startGroupPolling();
  startGroupsPolling(type);

  const rolePermissions = {
    [UserRole.ADMIN]: [
      MdtPermissions.CREATE_GROUP,
      MdtPermissions.EDIT_GROUP,
      MdtPermissions.DELETE_GROUP,
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_GROUP_MEMBER,
      MdtPermissions.DELETE_GROUP_MEMBER,
      MdtPermissions.EDIT_GROUP_MEMBER,
      MdtPermissions.READ_GROUP_MEMBER,
      MdtPermissions.CREATE_THREAD,
    ],
    [UserGroupRole.OWNER]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.EDIT_GROUP,
      MdtPermissions.DELETE_GROUP,
      MdtPermissions.CREATE_GROUP_MEMBER,
      MdtPermissions.EDIT_GROUP_MEMBER,
      MdtPermissions.READ_GROUP_MEMBER,
      MdtPermissions.DELETE_GROUP_MEMBER,
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.LEAVE_GROUP,
    ],
    [UserGroupRole.MANAGER]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.CREATE_GROUP_MEMBER,
      MdtPermissions.READ_GROUP_MEMBER,
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.LEAVE_GROUP,
    ],
    [UserGroupRole.DEFAULT]: [
      MdtPermissions.READ_GROUP,
      MdtPermissions.READ_GROUP_MEMBER,
      MdtPermissions.CREATE_THREAD,
      MdtPermissions.LEAVE_GROUP,
    ],
  };

  const onLeaveConfirm = async () => {
    setOpenLeaveConfirmModal(false);
    removeUserFromGroup(group.id, user.id);
    navigate(`/internal/groups/${group.type.toLowerCase()}`);

    setOpenGroupMenu(null);
  };

  const debouncedOnSearchChange = useCallback(
    debounce(async (term) => {
      if (term) {
        query.set('title', term);
      } else {
        query.delete('title');
      }

      navigate({ search: query.toString() });
    }, 2000),
    [],
  );

  const permissions =
    rolePermissions[getUserRole(user.scopes) === 'ADMIN' ? getUserRole(user.scopes) : groupUser.role] || [];

  const filters = (
    <>
      <ToggleButtonField
        text="My Threads"
        className={styles.toggleButton}
        onChange={(value) => {
          if (value) {
            query.set('userId', UserService.getLocalUser().id);
          } else {
            query.delete('userId');
          }

          navigate({ search: query.toString() });
        }}
      />
      <MdtThreadStatusFilter />

      <Search
        placeholder="Search for a thread"
        className={styles.search}
        search={search}
        onChange={(event) => {
          const term = event.target?.value;
          setSearch(term);
          debouncedOnSearchChange(term);
        }}
      />
    </>
  );

  if (!params.groupId) {
    return (
      <>
        <GroupsMenu permissions={permissions} title={title} type="MDT" groups={groups} />
        <GroupEmptyState />
      </>
    );
  }

  return (
    <>
      <GroupsMenu permissions={permissions} title={title} type="MDT" groups={groups} hideOnMobile />
      <div className={styles.threadsWrapper}>
        {groupError ? (
          <ErrorMessage className={styles.error}>{groupError}</ErrorMessage>
        ) : (
          <>
            <ThreadsHeader
              group={group}
              setOpenGroupMenu={setOpenGroupMenu}
              setOpenAddThreadModal={setOpenAddThreadModal}
              setOpenAddMembersGroupModal={
                hasPermission(permissions, MdtPermissions.CREATE_GROUP_MEMBER) && setOpenAddMembersGroupModal
              }
              filters={filters}
            />
            <MdtThreads threads={groupThreads} group={group} />
            <ThreadsPagination
              queryParams={query}
              totalCount={groupThreadsCount}
              pageSize={config.pagination.threadsPageSize}
            />

            <GroupEditMenu openGroupMenu={openGroupMenu} setOpenGroupMenu={setOpenGroupMenu}>
              <GroupEditMenuItem
                title="Settings"
                type="default"
                onClick={() => {
                  setOpenEditGroupModal(true);
                  setOpenGroupMenu(null);
                }}
              />

              <GroupEditMenuItem
                title="Members"
                type="default"
                onClick={() => {
                  setOpenMembersGroupModal(true);
                  setOpenGroupMenu(null);
                }}
              />

              {hasPermission(permissions, MdtPermissions.CREATE_GROUP_MEMBER) && (
                <GroupEditMenuItem
                  title="Add Member"
                  type="default"
                  onClick={() => {
                    setOpenAddMembersGroupModal(true);
                    setOpenGroupMenu(null);
                  }}
                />
              )}

              {hasPermission(permissions, MdtPermissions.LEAVE_GROUP) && (
                <GroupEditMenuItem title="Leave Group" type="leave" onClick={() => setOpenLeaveConfirmModal(true)} />
              )}
            </GroupEditMenu>

            <ThreadAddModal
              open={openAddThreadModal}
              setOpen={setOpenAddThreadModal}
              group={group}
              type={GroupType.MDT}
            />
            <GroupAddMemberModal
              permissions={permissions}
              open={openAddMembersGroupModal}
              setOpen={setOpenAddMembersGroupModal}
              group={group}
            />
            <GroupEditModal
              permissions={permissions}
              open={openEditGroupModal}
              setOpen={setOpenEditGroupModal}
              group={group}
            />
            <GroupManageMembersModal
              permissions={permissions}
              open={openMembersGroupModal}
              setOpen={setOpenMembersGroupModal}
            />
            <ConfirmDialog
              open={openLeaveConfirmModal}
              text="Are you sure you want to leave the group?"
              onClose={() => setOpenLeaveConfirmModal(false)}
              onConfirm={onLeaveConfirm}
              confirmName="Leave"
            />
          </>
        )}
      </div>
    </>
  );
}
