import React from 'react';
import { NavLink, useMatch } from 'react-router-dom';
import classNames from 'classnames';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import styles from './SidebarItem.module.scss';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';

const SidebarNavigationLink = React.forwardRef((props, ref) => {
  const match = useMatch({ path: props.to, exact: true, end: false });

  if (match?.pattern?.exact) {
    return (
      <div ref={ref} className={classNames({ [styles.activeLinkClass]: match?.pattern?.exact })}>
        <NavLink {...props} />
      </div>
    );
  }

  return (
    <div ref={ref}>
      <NavLink {...props} />
    </div>
  );
});

export default function SidebarItem(props) {
  const displayIcon = () => {
    if (props.loading) {
      return <CircularProgress className={styles.loading} />;
    }

    if (props.icon) {
      return <ListItemIcon className={styles.icon}>{props.icon}</ListItemIcon>;
    }
  };

  return (
    <Tooltip
      title={!props.open && props.text}
      TransitionComponent={Zoom}
      enterDelay={500}
      leaveDelay={200}
      placement="right"
      arrow
    >
      <ListItem className={classNames(styles.wrapper, props.className)} disablePadding>
        <ListItemButton
          to={props.to}
          component={props.to && SidebarNavigationLink}
          className={styles.button}
          onClick={props.onClick}
        >
          {displayIcon()}
          {props.open && <ListItemText className={styles.text} primary={props.text} />}
        </ListItemButton>
      </ListItem>
    </Tooltip>
  );
}
