import React, { useRef, useState } from 'react';
import { ButtonGroup, ClickAwayListener, Grow, MenuItem, MenuList, Paper, Popper, TextField } from '@mui/material';
import { useFormik } from 'formik';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

import { OnboardingStatus } from '../../../types/enums/OnboardingStatus';
import { OnboardingReviewDTO } from '../../../types/DTOs/OnboardingReviewDTO';
import Button from '../../button/Button';
import classes from './ReviewUserProfileForm.module.scss';

const STATUS_OPTIONS = [
  { value: OnboardingStatus.CHANGES_REQUESTED, label: 'Request Changes' },
  { value: OnboardingStatus.APPROVED, label: 'Approve' },
  { value: OnboardingStatus.REJECTED, label: 'Reject' },
];

interface Props {
  onSubmit: (values: OnboardingReviewDTO) => Promise<void>;
}

const ReviewUserProfileForm: React.FC<Props> = ({ onSubmit }) => {
  const anchorRef = useRef<HTMLButtonElement | null>(null);

  const [selectOpen, setSelectOpen] = useState(false);

  const { handleSubmit, handleBlur, isSubmitting, values, handleChange, setFieldValue } =
    useFormik<OnboardingReviewDTO>({
      initialValues: {
        onboardingReviewComment: '',
        status: OnboardingStatus.CHANGES_REQUESTED,
      },
      onSubmit: async (values, { resetForm }) => {
        await onSubmit(values);

        resetForm();
      },
    });

  const selectedOption = STATUS_OPTIONS.find((option) => option.value === values.status);

  const handleToggleSelect = () => setSelectOpen((prev) => !prev);
  const handleCloseSelect = () => setSelectOpen(false);

  const handleStatusChange = (status: OnboardingStatus) => {
    setFieldValue('status', status);
    handleCloseSelect();
  };

  return (
    <form onSubmit={handleSubmit}>
      <TextField
        className={classes.reviewComment}
        name="onboardingReviewComment"
        value={values.onboardingReviewComment}
        onChange={handleChange}
        onBlur={handleBlur}
        fullWidth
        multiline
        rows={2}
        placeholder="Onboarding Review Comment (optional)"
      />

      <ButtonGroup className={classes.buttonGroup} variant="contained">
        <Button
          loading={isSubmitting}
          variant="contained"
          type="submit"
          className={classes.actionButton}
          refAnchor={anchorRef}
        >
          {selectedOption?.label}
        </Button>

        <Button className={classes.optionsButton} type="button" disabled={isSubmitting} onClick={handleToggleSelect}>
          <ArrowDropDownIcon />
        </Button>
      </ButtonGroup>

      <Popper
        sx={{ zIndex: 1, width: 'max-content' }}
        open={selectOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'right top' : 'right bottom',
              width: anchorRef.current ? anchorRef.current.offsetWidth : undefined,
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleCloseSelect}>
                <MenuList autoFocusItem>
                  {STATUS_OPTIONS.map((option) => (
                    <MenuItem
                      key={option.value}
                      selected={option.value === values.status}
                      onClick={() => handleStatusChange(option.value)}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </form>
  );
};

export default ReviewUserProfileForm;
