import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import styles from './MdtOnboardingStepWrapper.module.scss';
import classNames from 'classnames';
import Button from '../../../../../components/button/Button';

export default function MdtOnboardingStepWrapper({
  formik,
  name,
  children,
  nextDisabled,
  backDisabled,
  nextLabel,
  handleBack,
  hideButtons,
}) {
  return (
    <form component="form" onSubmit={formik.handleSubmit} noValidate>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Typography component="h5" variant="h5" className={styles.title}>
            {name}
          </Typography>
        </Grid>

        {children}

        <Grid className={classNames(styles.buttonContainer, { [styles.buttonHidden]: hideButtons })}>
          <Button className={styles.button} disabled={backDisabled} onClick={handleBack} type="button">
            Back
          </Button>
          <Button disabled={nextDisabled} loading={formik.isSubmitting} className={styles.button} type="submit">
            {nextLabel || 'Next'}
          </Button>
        </Grid>
      </Grid>
    </form>
  );
}
