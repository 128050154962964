import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import { useAuthContext } from '../../hooks/useAuthContext';
import ROUTER from '../../routes/Router.constants';

const OnboardingLayout = () => {
  const { isOnboarding, user } = useAuthContext();

  if (user && !isOnboarding) return <Navigate to={`/${ROUTER.PROTECTED.INDEX.PATH}`} replace />;

  return <Outlet />;
};

export default OnboardingLayout;
