import Box from '@mui/material/Box';

import styles from './DashboardPage.module.scss';
import DasboardPersonalThreads from './components/DasboardPersonalThreads';
import DasboardStats from './components/DasboardStats';

export default function DashboardPage() {
  return (
    <Box component="main" className={styles.wrapper}>
      <DasboardStats />
      <DasboardPersonalThreads />
    </Box>
  );
}
