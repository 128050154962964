import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Feedback } from '../../../types/Feedback';
import { SliceName } from '../../config';
import { RootState } from '../../store';
import { FEEDBACK_ACTIONS } from './feedbackActionTypes';
import { fulfilledActionToFeedback, rejectedActionToFeedback } from './feedbackSliceUtils';

type FeedbackSlice = {
  feedback: Feedback | null;
  loaderQueue: number;
};

const initialState: FeedbackSlice = {
  feedback: null,
  loaderQueue: 0,
};

const feedbackSlice = createSlice({
  name: SliceName.FEEDBACK,
  initialState,
  reducers: {
    showFeedback: (state, action: PayloadAction<Feedback>) => {
      state.feedback = action.payload;
    },
  },
  extraReducers: (builder) => {
    FEEDBACK_ACTIONS.forEach(
      ({ action, showError, errorMessage, defaultErrorMessage, showSuccess, successMessage, showLoader }) => {
        if (showLoader) {
          builder.addCase(action.pending, (state) => {
            state.loaderQueue += 1;
          });
        }

        const hasRejectedCase = showError || errorMessage || defaultErrorMessage || showLoader;

        if (hasRejectedCase) {
          builder.addCase(action.rejected, (state, action) => {
            if (showLoader) {
              state.loaderQueue -= 1;
            }

            if (showError || errorMessage || defaultErrorMessage) {
              state.feedback = rejectedActionToFeedback(action, errorMessage || defaultErrorMessage);
            }
          });
        }

        const hasFulfilledCase = showSuccess || successMessage || showLoader;

        if (hasFulfilledCase) {
          builder.addCase(action.fulfilled, (state) => {
            if (showLoader) {
              state.loaderQueue -= 1;
            }

            if (showSuccess || successMessage) {
              state.feedback = fulfilledActionToFeedback(successMessage);
            }
          });
        }
      },
    );
  },
});

export const { showFeedback } = feedbackSlice.actions;

export const selectFeedback = (state: RootState) => state.feedback;

export default feedbackSlice.reducer;
