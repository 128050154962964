import React from 'react';
import { createRoot } from 'react-dom/client';
import TagManager from 'react-gtm-module';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthContext';
import { LoadingProvider } from './context/LoadingContext';
import { config } from './configs/config';
import { Provider } from 'react-redux';
import { store } from './store/store';

TagManager.initialize({ gtmId: config.googleTagId || '' });

if ('serviceWorker' in navigator) {
  navigator.serviceWorker
    .register('/service-worker.js')
    .then((registration) => {
      // eslint-disable-next-line no-console
      console.log('Service Worker registered with scope:', registration.scope);
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Service Worker registration failed:', error);
    });
}

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(
  <LoadingProvider>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </LoadingProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
