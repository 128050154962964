import Content from '../components/content/Content';
import Footer from '../components/footer/Footer';
import Header from '../components/header/Header';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

export default function PublicLayout() {
  const auth = useAuthContext();
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  if (auth.isAuthenticated()) {
    const path = auth.isBasic() || auth.isAdmin() ? '/internal/home' : '/internal/onboarding';
    return <Navigate to={path} />;
  }

  return (
    <>
      <Header />
      <Content>
        <Outlet />
      </Content>
      <Footer />
    </>
  );
}
