import React, { useState } from 'react';
import { List } from '@mui/material';

import SidebarExpandableList from '../SidebarExpandableList';
import SidebarExpandedItem from '../SidebarExpandableItem';
import SidebarItem from '../SidebarItem';
import SidebarMenu from '../SidebarMenu';

interface Props {
  open: boolean;
  label: string;
  to: string;
  icon: React.ReactNode;
  items: {
    label: string;
    to: string;
  }[];
}

const SidebarExpandableItem: React.FC<Props> = ({ open, label, to, icon, items }) => {
  const [adminMenuAnchorEl, setAdminMenuAnchorEl] = useState<HTMLElement | null>(null);

  if (open) {
    return (
      <SidebarExpandableList
        open={open}
        text={label}
        to={to}
        icon={icon}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
        }}
        items={
          <List component="div" disablePadding>
            {items.map(({ label, to }) => (
              <SidebarExpandedItem key={to} open={open} text={label} to={to} />
            ))}
          </List>
        }
      />
    );
  }

  return (
    <>
      <SidebarItem
        open={open}
        text={label}
        to={to}
        icon={icon}
        onClick={(event: React.MouseEvent<HTMLElement>) => {
          event.preventDefault();
          setAdminMenuAnchorEl(event.currentTarget);
        }}
      />

      <SidebarMenu menuAnchorEl={adminMenuAnchorEl} setMenuAnchorEl={setAdminMenuAnchorEl}>
        {items.map(({ label, to }) => (
          <SidebarItem
            key={to}
            open={!!adminMenuAnchorEl}
            onClick={() => setAdminMenuAnchorEl(null)}
            text={label}
            to={to}
          />
        ))}
      </SidebarMenu>
    </>
  );
};

export default SidebarExpandableItem;
