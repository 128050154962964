import Home from './components/Home';
import Services from './components/Services';
import HowItWorks from './components/HowItWorks';
import ContactUs from './components/ContactUs';
import Partners from './components/Partners';

export const LandingSectionIds = {
  home: 'home',
  services: 'services',
  howItWorks: 'how-it-works',
  contactUs: 'contact-us',
};

export default function LandingPage() {
  return (
    <div>
      <Home id={LandingSectionIds.home} />
      <Services id={LandingSectionIds.services} />
      <HowItWorks id={LandingSectionIds.howItWorks} />
      <Partners />
      <ContactUs id={LandingSectionIds.contactUs} />
    </div>
  );
}
