import React, { createContext, useEffect } from 'react';
import UserService from '../services/api/UserService';
import AuthService from '../services/api/AuthService';
import { UserScope, UserStatus } from '../constants/user.constants';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getProfile } from '../store/slices/profile/profileThunks';
import { selectProfile } from '../store/slices/profile/profileSlice';

export const AuthContext = createContext({});
export const AuthProvider = ({ children }) => {
  const dispach = useAppDispatch();

  const { profile } = useAppSelector(selectProfile);
  const user = profile;

  const isOnboarding = user && [UserStatus.INACTIVE, UserStatus.PENDING].includes(user.status) && !user.scopes.length;

  useEffect(() => {
    const accessToken = AuthService.getLocalAccessToken();

    if (accessToken) {
      dispach(getProfile());
    }
  }, []);

  const getScopes = () => {
    if (user?.scopes) return user?.scopes;

    const localUser = UserService.getLocalUser();
    return localUser?.scopes || [];
  };

  const isAuthenticated = () => {
    return !!profile || !!AuthService.getLocalAccessToken();
  };

  const isAdmin = () => {
    const scopes = getScopes();

    return scopes.includes(UserScope.ADMIN);
  };

  const isBasic = () => {
    const scopes = getScopes();

    return scopes.includes(UserScope.BASIC);
  };

  const isActive = () => {
    const status = user?.status || UserService.getLocalUser()?.status;

    return status === UserStatus.ACTIVE || isAdmin();
  };

  return (
    <AuthContext.Provider value={{ user, isAuthenticated, isAdmin, isBasic, isActive, isOnboarding }}>
      {children}
    </AuthContext.Provider>
  );
};
